import { appSignal, getClass, useApp } from 'signals';
import { Grid10 } from 'components';
import { AppIndexItem } from './AppIndexItem';
import { is } from 'aios';

export const AppIndex = (): JSX.Element => {
  const { root } = useApp();
  if (!is(appSignal.value.indexMenu)) {
    return <></>;
  }
  return (
    <div className={getClass('app-index')}>
      <Grid10>
        <AppIndexItem node={root} />
      </Grid10>
    </div>
  );
};
