import { isFail, isWait, is, AiosStatus } from 'aios';
import { type AiosNode } from 'app';
import { saveNodeData } from './saveNodeData';
import { getAiosNodeAction, setAiosNodeAction } from 'app/AiosNode/AiosNodeApi';

export async function saveNodeNext(node: AiosNode): Promise<boolean | undefined> {
  const { progress } = getAiosNodeAction(node);
  if (!is(progress) || progress < 0.7) {
    setAiosNodeAction(node, { text: 'saving next', status: AiosStatus.Processing, progress: 0.7 });
    return undefined;
  }
  if (progress < 0.8) {
    setAiosNodeAction(node, { text: 'saving data', status: AiosStatus.Processing, progress: 0.8 });
    const action = await saveNodeData(node);
    if (isFail(action.output?.status)) {
      return false;
    }
    setAiosNodeAction(node, { text: 'saving data', status: AiosStatus.Processing, progress: 0.9 });
    return undefined;
  }
  if (progress < 0.9) {
    return undefined;
  }
  if (progress < 1.0) {
    setAiosNodeAction(node, { text: 'saving data', status: AiosStatus.Ok, progress: 1.0 });
    let nodes = node.nodes;
    if (is(nodes)) {
      nodes = nodes.filter((node) => !is(node.pathEx.action));
    }
    if (is(nodes)) {
      try {
        // eslint-disable-next-line @typescript-eslint/promise-function-async
        const promises = nodes.map((node) => saveNodeData(node));
        await Promise.all(promises);
        nodes.forEach(child => {
          const { status: childStatus } = getAiosNodeAction(child);
          if (isFail(childStatus)) {
            setAiosNodeAction(node, { status: AiosStatus.Fail });
            return false;
          }
          if (isWait(childStatus)) {
            setAiosNodeAction(node, { text: 'saving data', status: AiosStatus.Processing, progress: 0.9 });
            return undefined;        
          }
        });
      } catch (exception) {
        // output.setException(exception as AiosException);
        setAiosNodeAction(node, { status: AiosStatus.Fail });
        return false;
      }
    }
  }
  return true;
}
