import React from 'react';
import { AppContext } from './AppContext';
import { type AppState } from '../../signals/AppState/AppState';

export function useApp(): AppState {
  const context = React.useContext(AppContext);
  if (context === undefined) {
    throw new Error('useApp must be used within a AppProvider');
  }
  return context;
}
