export const anon = '00000000-0000-0000-0000-000000000000';
const regex =
  /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

export function isId(id?: string): id is string {
  if (id === undefined || id === null || id === '') {
    return false;
  }
  if (id === '' || id === anon) {
    return false;
  }
  const isValid = regex.test(id);
  return isValid;
}

export default isId;
