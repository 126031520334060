import { AiosStatus, is, createAiosAction, createAiosOutput } from 'aios';
import { type AiosNode } from 'app/AiosNode';
import { getAiosNodeAction, setAiosNodeAction } from 'app/AiosNode/AiosNodeApi';
import { AppState } from 'signals/AppState/AppState';

export async function doLoadCancel(node: AiosNode): Promise<void> {
    const { output } = getAiosNodeAction(node);
    output.setFail({ status: AiosStatus.FailCancel });
    if (is(node.api.client)) {
      node.api.client.cancel();
    }
    AppState.current.refresh();
  }
  
  export async function doReload(node: AiosNode): Promise<void> {
    setAiosNodeAction(node, { action: createAiosAction({ output: createAiosOutput() }) });
    const { pathEx } = node;
    if (is(pathEx)) {
      AppState.current.goTo(pathEx);
    }
  }