import { type AiosPath } from './AiosPath';
import { is, set } from '../functions';

export const isChildPath = (path: AiosPath, target: AiosPath): boolean => {
  const parentPath = path.path as string;
  const childPath = target.path as string;
  if (!is(parentPath) || !is(childPath)) {
    return false;
  }
  if (childPath.startsWith(parentPath)) {
    const rem = childPath.substring(parentPath.length);
    if (is(rem) && !rem.includes('/')) {
      return false;
    }
    if (is(path.mode)) {
      if (!is(target.mode)) {
        return false;
      }
      if (is(path.modeId) && path.modeId !== target.modeId) {
        return false;
      }
    }
    return true;
  }
  if (is(path.assetPath) && is(target.assetPath)) {
    const parentAssetPath = `${set(path.account, '')}/${
      set(
        path.assetPath,
        '',
      )
    }`;
    const childAssetPath = `${set(target.account, '')}/${
      set(
        target.assetPath,
        '',
      )
    }`;
    if (childAssetPath.startsWith(parentAssetPath)) {
      if (childAssetPath === parentAssetPath) {
        if (is(path.version) && path.version !== 'all') {
          if ((target.version === 'all' || target.version === 'new')) {
            return false;
          }
        }
      }
      return true;
    }
  }
  return false;
};

export default isChildPath;
