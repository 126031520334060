import { createAiosFile } from 'aios';
import { AdminBody } from './AdminBody';
import { AdminFoot } from './AdminFoot';
import { useState } from 'react';

const folder = createAiosFile({
  path: 'folder', files: [
    createAiosFile({ path: 'folder/file' })
  ]
});

export const Admin = (): JSX.Element => {
  const [selected, setSelected] = useState(folder);
  return (
    <>
      <div>{selected?.path}</div>
      <AdminBody file={folder} onSelect={setSelected} />
      <AdminFoot />
    </>
  );
};
