import { type AiosAction, AiosDataCommand, is, isOk, rem } from 'aios';
import { processNodeData, setAiosNodeAction } from 'app/AiosNode/AiosNodeApi';
import { type AiosNode } from '../../AiosNode';

export async function removeNodeData(node: AiosNode): Promise<AiosAction> {
  const action = await processNodeData(node, AiosDataCommand.Remove);
  setAiosNodeAction(node, { action });
 
  // addAiosNodeAction(node, action);
  return action;

  // const parent = this.parent as AiosNode;
  // if (!is(parent)) {
  //   return;
  // }
  // const parentData = parent.data;
  // if (!is(parentData)) {
  //   return;
  // }
  // const parentFull = parent.path.full as string;
  // switch (parentData.type) {
  //   case AiosType.Account:
  //     {
  //       const account = parentData.item as AiosAccount;
  //       if (is(account.assets)) {
  //         account.assets = account.assets.filter((a) => a.path !== thisFull);
  //       }
  //       await cache.save(parentFull, account);
  //     }
  //     break;
  //   case AiosType.Asset:
  //     {
  //       const asset = parentData.item as AiosAsset;
  //       if (is(asset.assets)) {
  //         asset.assets = asset.assets.filter((a) => a.path !== thisFull);
  //       }
  //       await cache.save(parentFull, asset);
  //     }
  //     break;
  // }
}
