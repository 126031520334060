import { type ClassOptions, getClass } from 'signals';
import { is } from 'aios';

export interface GridCellProps {
  test?: string;
  spanx?: number;
  spany?: number;
  space?: boolean;
  height?: number;
  options?: ClassOptions;
  children?: React.ReactNode;
  subOptions?: ClassOptions;
}

export const GridCell = ({ spanx, spany, space, height, options, children }: GridCellProps): JSX.Element => {
  const style = {
    height: '',
    gridColumn: '',
    gridRow: '',
    margin: ''
  };
  if (is(height)) {
    style.height = `${height}vh`;
  }
  if (is(spanx)) {
    style.gridColumn = `span ${spanx}`;
  }
  if (is(spany)) {
    style.gridRow = `span ${spany}`;
  }
  let className = 'grid-cell';
  if (is(space)) {
    className = 'grid-cell space';
  }
  className = getClass(className, options);
  if (is(options?.margin)) {
    style.margin = '4px';
  }
  return (
    <div style={style} className={className}>
      {children}
    </div>
  );
}