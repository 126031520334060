import { type AiosClient, type AiosAuth, createAiosClient, is } from 'aios';
import { getConfig } from './getConfig';

let auth: AiosAuth;

export function createAiosClientApp(options?: Partial<AiosClient>): AiosClient {
  if (!is(auth) && is(options?.auth)) {
    auth = options?.auth as AiosAuth;
  }
  const aiosClient = createAiosClient({ ...options, auth, url: getConfig('REACT_APP_AIOS_API') });
  return aiosClient;
}

export default createAiosClientApp;
