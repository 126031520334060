import { type ReactNode } from 'react';
import './Grid.css';

export interface GridRowProps {
  children?: ReactNode;
}

export const GridRow = ({ children }: GridRowProps): JSX.Element => {
  return (
    <>
      {children}
    </>
  );
}