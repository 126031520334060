import { appSignal, getClass } from 'signals';
import { is } from 'aios';
import './Grid.css';

export interface GridExProps {
  size?: number;
  rows?: boolean;
  cols0?: number;
  cols1?: number;
  cols2?: number;
  children?: React.ReactNode;
}

export const GridEx = ({ size = 1, cols0 = 0, cols1 = 0, cols2 = 0, rows, children }: GridExProps): JSX.Element => {
  const style = {
    gridTemplateColumns: '',
    gridAutoRows: ''
  };
  let square = `${size}vh`;
  if (is(appSignal.value.narrow)) {
    square = `${size}vw`;
  }
  if (is(rows)) {
    style.gridAutoRows = square;
  }
  while (cols0 > 0) {
    style.gridTemplateColumns = `${style.gridTemplateColumns} ${square}`;
    cols0 = cols0 - 1;
  }
  while (cols1 > 0) {
    style.gridTemplateColumns = `${style.gridTemplateColumns} 1fr`;
    cols1 = cols1 - 1;
  }
  while (cols2 > 0) {
    style.gridTemplateColumns = `${style.gridTemplateColumns} ${square}`;
    cols2 = cols2 - 1;
  }
  let className = 'grid';
  // if (is(float)) {
  //   className = `${className} float`
  // }
  // if (is(right)) {
  //   className = `${className} right`
  // }
  // if (is(center)) {
  //   className = `${className} center`
  // }
  className = getClass(className);
  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
}