import {
  type AiosPath,
  type AiosAccount,
  type AiosAsset,
  type AiosFile,
  type AiosObject,
  AiosType,
  AiosFileType,
  createAiosAccount,
  createAiosAsset,
  createAiosObject,
  is,
  updateAiosAccount,
  updateAiosAsset,
} from 'aios';
import { type AiosNode, type AiosNodeItem } from './AiosNode';
import { createAiosAssetSample } from './sample';

export interface AiosNodeData {
  path?: AiosPath;
  type?: AiosType;
  item?: AiosNodeItem;
  account?: AiosAccount;
  asset?: AiosAsset;
  assetPath?: string;
  assetFile?: AiosFile;
  assetFileType?: AiosFileType;
  assetFileText?: string;
  assetFileBase?: string;
  jsonObject?: AiosObject;
  jsonText?: string;
  cloneCount?: number;
  cloneIndex?: number;
}

export function getIcon(node: AiosNode): string {
  const { type, item } = node;
  const { path, version, mode } = node.pathEx;
  if (path === 'aios') {
    return 'aios';
  }
  if (is(mode)) {
    return 'access';
  }
  if (is(version)) {
    // if (version === 'all'){
    //   return 'version';
    // }
    return 'asset';
  }
  // todo: get asset data
  switch (type) {
    case AiosType.Account:
      return 'account';
    case AiosType.Asset:
      {
        const asset = item as AiosAsset;
        if (is(asset)) {
          switch (asset.file?.type) {
            case AiosFileType.Container:
              return 'folder';
            case AiosFileType.Content:
              return 'markdown';
            case AiosFileType.Code:
              return 'code';
            case AiosFileType.Data:
              return 'data';
            case AiosFileType.Text:
              return 'text';
            case AiosFileType.Json:
              return 'json';
            case AiosFileType.Image:
              return 'image';
            case AiosFileType.Audio:
              return 'audio';
            case AiosFileType.Model:
              return 'model';
            case AiosFileType.Video:
              return 'video';
          }
        }
        return 'asset';
      }
  }
  return 'aios';
}

export function getName(node: AiosNode): string {
  const { path, name, version, mode, modeId } = node.pathEx;
  if (is(mode)) {
    if (!is(modeId)) {
      return 'access';
    }
    return modeId;
  }
  if (is(version)) {
    if (version === 'all') {
      return 'version';
    }
    return version;
  }
  if (is(name) && name.includes('new')) {
    return name;
  }
  switch (node.type) {
    case AiosType.Asset:
      {
        const asset = node.item as AiosAsset;
        const assetName = asset?.name;
        if (is(assetName)) {
          return assetName;
        }
      }
  }
  if (is(name)) {
    return name;
  }
  if (is(path)) {
    return path;
  }

  return '?';
}

export function getAiosNodeData(node: AiosNode): AiosNodeData {
  const data: AiosNodeData = {
    path: node.pathEx,
    type: node.type,
    item: node.item,
  };
  switch (data.type) {
    case AiosType.Account:
      data.account = data.item as AiosAccount;
      break;
    case AiosType.Asset:
      data.asset = data.item as AiosAsset;
      data.assetPath = data.asset?.path;
      data.assetFile = data.asset?.file;
      data.assetFileType = data.assetFile?.type;
      data.assetFileText = data.assetFile?.text;
      data.assetFileBase = data.assetFile?.base;
      break;
  }
  const createJsonObject = (jsonNode: AiosNode): AiosObject => {
    let childNodes;
    if (is(jsonNode.nodes)) {
      childNodes = jsonNode.nodes.map(c => createJsonObject(c))
    }
    const jsonObject = createAiosObject({
      path: jsonNode.path,
      type: jsonNode.type,
      item: jsonNode.item,
      nodes: childNodes
    }) as AiosObject;
    return jsonObject;
  }
  data.jsonObject = createJsonObject(node);
  data.jsonText = JSON.stringify(data.jsonObject, undefined, 2);
  if (is(node.clones)) {
    data.cloneCount = node.clones.length;
  }
  if (node.clonei !== undefined) {
    data.cloneIndex = node.clonei
  }
  return data;
}

export function setAiosNodeData(node: AiosNode, data: AiosNodeData): void {
  const { type, item } = node;
  const { path: setPath, item: setItem, jsonText: setJsonText } = data;
  if (is(setPath)) {
    node.pathEx = setPath;
  }
  if (is(setItem)) {
    switch (type) {
      case AiosType.Account:
        if (!is(item)) {
          node.item = createAiosAccount(setItem);
        } else {
          node.item = updateAiosAccount(item, setItem);
        }
        // if (!is(store)) {
        //   node.store = createAiosNode({ ...node, item: createAiosAccount(node.item) });
        // }
        break;
      case AiosType.Asset:
        {
          const asset = node.item as AiosAsset;
          const updated = setItem as AiosAsset;
          const assetType = asset.file?.type;
          const updatedType = updated.file?.type;
          if (is(updatedType) && assetType !== updatedType) {
            const parentPath = node.parent?.path as string;
            node.item = createAiosAssetSample(updatedType, parentPath);
          } else {
            if (!is(item)) {
              node.item = createAiosAsset(setItem);
            } else {
              node.item = updateAiosAsset(item, setItem);
            }

          }
          // if (is(updatedType) && asset.file?.type !== updatedType) {
          //   const parentPath = this.parent?.path?.path as string;
          //   data.item = createAiosAssetSample(updatedType, parentPath);
          // } else {
          //   data.item = updateAiosAsset(data.item as AiosAsset, update);
          // }
          // if (is(this.path.action)) {
          //   this.url = createAiosPath({ path: asset.path, action: this.path.action }).full;
          // }

          // if (!is(origin)) {
          //   node.store = updateAiosAsset(node.item);
          // }
        }
        break;
      default:
        data.item = setItem;
        break;
    }
  }
  if (is(setJsonText)) {
    // node.data.jsonObject = JSON.parse(data.jsonText);
    // node.item = JSON.parse(jsonText);
    // options.data.item = createAiosObject(data?.item) as AiosObject;
  }

  // // if (!is(data.changed)) {
  // //   data.changed = true;
  // // }

}
