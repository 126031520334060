import {
  type AiosType,
  type AiosException,
  AiosStatus,
  AiosDataCommand,
  createAiosDataOutput,
  is,
  createAiosData,
  createAiosAction,
  createAiosDataInput,
  set,
} from 'aios';
import { type AiosNode } from 'app';
import { getAiosNodeAction, setAiosNodeAction } from 'app/AiosNode/AiosNodeApi';
import { createAiosClientApp } from 'utl';

export async function loadNodeData(node: AiosNode): Promise<boolean> {
  const { loaded } = getAiosNodeAction(node);
  if (is(loaded)) {
    return true;
  }
  const { output } = getAiosNodeAction(node);
  const text = set(output?.text, 'loading');
  try {
    const apiInput = createAiosDataInput({
      type: node.type,
      item: node.item,
      command: AiosDataCommand.Load,
    });
    setAiosNodeAction(node, {
      action: createAiosAction(
        {
          input: apiInput,
          output: createAiosDataOutput({ status: AiosStatus.Processing })
        }
      )
    });

    // const thisFull = node.path.full as string;
    // const cache = createAppCache();
    // const cached = await cache.load(thisFull);
    // if (!is(cached)) {
    if (!is(apiInput.type)) {
      apiInput.type = node.pathEx.aiosType as AiosType;
    }
    if (!is(apiInput.item)) {
      apiInput.item = createAiosData({ path: node.pathEx.full });
    }
    node.api.client = createAiosClientApp();
    const apiOutput = await node.api.client.send({ path: node.pathEx, input: apiInput });
    node.api.client = undefined;
    setAiosNodeAction(node, {
      action: createAiosAction(
        {
          input: apiInput,
          output: apiOutput
        }
      )
    });
    apiOutput.text = text;
    if (apiOutput?.status === AiosStatus.FailCancel) {
      return false;
    }
    // } else {
    //   node.action.output = createAiosDataOutput({
    //     text,
    //     status: AiosStatus.OkCache,
    //     item: cached,
    //   });
    // }
    node.item = apiOutput.item;
    node.items = apiOutput.items;
    node.api.loaded = true;
    // result.text = text;

    // if (is(node.data.item) && !is(node.data.items)) {
    //   await cache.save(thisFull, node.data.item);
    // }
  } catch (exception) {
    if (is(output)) {
      output.setFail({ text, exception: exception as AiosException });
    }
  }
  return true;
}
