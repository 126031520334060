import { add, AiosStatus, createAiosPath, delay, is, isFail, isWait, rem } from 'aios';
import { createAiosNode, type AiosNode } from 'app/AiosNode';
import { getAiosNodeAction, setAiosNodeAction } from 'app/AiosNode/AiosNodeApi';
import { AppState } from 'signals/AppState/AppState';
import { saveNodeNext } from './saveNodeNext';
import { setAiosNodeUiData } from 'app/AiosNode/AiosNodeUi';

export async function saveNode(node: AiosNode): Promise<void> {
    const { status, progress } = getAiosNodeAction(node);
    if (isFail(status) || !isWait(status)) {
        return;
    }
    if (!is(progress) || progress < 0.6) {
        setAiosNodeAction(node, { text: 'saving', status: AiosStatus.Processing, progress: 0.6 });
        setAiosNodeUiData(node, { form: node.ui.form, functions: { cancel: undefined } });
        void delay(async () => { await saveNode(node); });
        AppState.current.refresh();
        return;
    }
    if (progress < 1.0) {
        if (progress === 0.6) {
            setAiosNodeAction(node, { text: 'saving next', status: AiosStatus.Processing, progress: 0.7 });
        }
        const saved = await saveNodeNext(node);
        if (saved === undefined) {
            void delay(async () => { await saveNode(node); });
            AppState.current.refresh();
            return;
        }
        if (!is(saved)) {
            setAiosNodeAction(node, { text: 'saving next', status: AiosStatus.Fail, progress: undefined });
            return;
        }
    }
    const objectNode = node.parent as AiosNode;
    const actionNode = objectNode.parent as AiosNode;
    const rootNode = objectNode?.parent?.parent as AiosNode;
    if (is(rootNode)) {
        if (is(actionNode)) {
            rootNode.nodes = rem(rootNode.nodes, actionNode);
        }
        if (is(objectNode)) {
            const path = createAiosPath({ path: node.item?.path });
            const newNode = createAiosNode({
                parent: rootNode,
                type: node.type,
                pathEx: path,
                item: node.item,
            });
            setAiosNodeAction(newNode, { loaded: true });
            rootNode.nodes = add(rootNode.nodes, newNode);
            AppState.current.goTo(path);
        }
        return;

        // remove action
        //   // const create = node.parent?.parent;
        //   // root.nodes = rem(root.nodes, create);
    }

    setAiosNodeAction(node, { text: 'saving', status: AiosStatus.Ok, progress: 1.0 });
    AppState.current.refresh();
};