import {
  AiosStatus,
  createAiosOutput,
  is,
  isWait,
  delay,
  createAiosAction,
} from 'aios';
import { type AiosNode } from '../../AiosNode';
import { AppState } from 'signals/AppState/AppState';
import { getAiosNodeAction, setAiosNodeAction } from 'app/AiosNode/AiosNodeApi';

export async function doRun(node: AiosNode, input?: string): Promise<void> {
  setAiosNodeAction(node, {
    action: createAiosAction({
      output: createAiosOutput({
        text: `${input ?? 'running'}`,
        status: AiosStatus.Processing,
        progress: 0.1,
      })
    })
  });
  void delay(async () => {
    await doRunData(node, input);
  });
  AppState.current.refresh();
}

export async function doRunCancel(node: AiosNode): Promise<void> {
  const { output } = getAiosNodeAction(node);
  if (output?.progress === 0.1) {
    output.setFail({ status: AiosStatus.FailCancel });
    AppState.current.refresh();
  }
  if (is(node.api.client)) {
    node.api.client.cancel();
  }
}

async function doRunData(node: AiosNode, input?: string): Promise<void> {
  const { output, status } = getAiosNodeAction(node);
  if (!is(output)) {
    return;
  }
  if (!isWait(status)) {
    return;
  }
  // output.progress = 0.2;
  // const { assetPath } = getAiosNodeData(this);
  // const code: AiosAction = {
  //   path: assetPath,
  //   input: { item: { text: input } },
  // };
  // // setAiosNodeAction(this, { input: `running ${this.path.path as string}(${code.input as string})`, output: 'Processing' });
  // this.api.client = createAiosClientApp();
  // this.action = createAiosAction(
  //   {
  //     input: createAiosDataInput<AiosAction>({
  //       type: this.path.aiosType as AiosType,
  //       item: code,
  //       command: AiosDataCommand.Run,
  //     }) as AiosDataInput<AiosData>,
  //     output: createAiosDataOutput({ status: AiosStatus.Processing })
  //   }
  // );
  // const result = await this.api.client.send({ path: this.path, input: this.action.input as AiosDataInput<AiosData> });
  // this.api.client = undefined;
  // this.action.output = result;
  // output.override(result as AiosOutput);
  // if (isFail(this.action?.output?.status)) {
  //   // this.addAction({ input: this.interface.action?.input, output: this.action?.output?.status });
  //   AppState.current.refresh();
  //   return;
  // }
  // // const item = result?.item as AiosAction;
  // // if (is(item)) {
  // //   this.addAction({
  // //     to: 'you',
  // //     from: 'ai',
  // //     input: `running ${this.path.path as string}(${code.input as string})`,
  // //     output: item?.output as string
  // //   });
  // // }
  AppState.current.refresh();
}

// if (is(this.process.output)) {
//   this.result.override(this.process.output);
// }
// const result = this.process.output as AiosDataOutput<AiosAsset>;
// this.interface.showHistory({
//   from: 'aios',
//   name: 'openai',
//   text: `${result?.item?.file?.text as string}`,
//   result: this.result,
//   process: this.process,
// });

// 

