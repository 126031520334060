import { AiosStatus, createAiosPath, is, rem } from 'aios';
import { saveNode, type AiosNode } from 'app';
import { AppState } from 'signals/AppState/AppState';
import { setAiosNodeAction, getAiosNodeAction } from 'app/AiosNode/AiosNodeApi';

export async function doCreateCancel(node: AiosNode): Promise<void> {
  const remove = node.parent;
  const parent = remove?.parent;
  if (!is(parent) || !is(parent?.nodes)) {
    return;
  }
  parent.nodes = rem(parent.nodes, remove);
  AppState.current.goTo(createAiosPath({ path: parent.pathEx.path }));
}

export async function doSave(node: AiosNode): Promise<void> {
  setAiosNodeAction(node, { text: 'saving', status: AiosStatus.Processing, progress: 0.5 });
  await saveNode(node);
}

export async function doSaveCancel(node: AiosNode): Promise<void> {
  const { output } = getAiosNodeAction(node);
  output.setFail({ status: AiosStatus.FailCancel });
  // todo: still need to wait for client to finish for no rouge finishes
  // if (is(this.api.client)) {
  //   this.api.client.cancel();
  // }
}

// export async function doSaveConfirm(node: AiosNode): Promise<void> {
//   setAiosNodeAction(node, { text: 'saving node', status: AiosStatus.Processing, progress: 0.5 });
//   void delay(async (): Promise<void> => {
//     await saveNode(node);
//   });
//   AppState.current.refresh();
// }

// export async function doSubmit(node: AiosNode): Promise<void> {
//   const doSubmit = node.ui.doSubmit;
//   if (is(doSubmit)) {
//     void doSubmit();
//   }
// }

// export async function doCancel(node: AiosNode): Promise<void> {
//   // const parent = this.parent;
//   // if (!is(parent)) {
//   //   return;
//   // }
//   // await AppState.current.root.remove(parent?.path);
//   // await removeAiosNode(AppState.current.root, node.path);
//   // AppState.current.refresh();
// }

// export async function doReset(this: AiosNode): Promise<void> {
//   const doReset = this.ui.doReset;
//   if (is(doReset)) {
//     doReset();
//   }
// }

// export function doSave(node: AiosNode): void {
//   const { status } = getAiosNodeAction(node);
//   if (isFail(status)) {
//     return;
//   }
//   if (isWait(status)) {
//     return;
//   }
//   setAiosNodeAction(node, {
//     action: createAiosAction({
//       output: createAiosOutput({
//         text: `staging save '${node.pathEx.path as string}'`,
//         status: AiosStatus.Processing,
//         progress: 0.1,
//       })
//     })
//   });
//   const save = node.parent;
//   if (!is(save)) {
//     return;
//   }
//   setAiosNodeAction(save, {
//     action: createAiosAction({
//       output: createAiosOutput({
//         text: `confirming save '${save.pathEx.path as string}'?`,
//         status: AiosStatus.Processing,
//         progress: 0.1,
//       })
//     })
//   });
//   if (is(save.nodes)) {
//     for (const child of save.nodes) {
//       if (!is(child.pathEx.mode) && !is(child.pathEx.action)) {
//         setAiosNodeAction(child, {
//           action: createAiosAction({
//             output: createAiosOutput({
//               text: `confirming save '${child.pathEx.path as string}'?`,
//               status: AiosStatus.Processing,
//               progress: 0.1,
//             })
//           })
//         });
//       }
//     }
//   }
// }

// async function doSaveNext(node: AiosNode): Promise<void> {
//   // const { output, status, progress } = getAiosNodeAction(node);
//   // if (isFail(status)) {
//   //   return;
//   // }
//   // if (!is(progress)) {
//   //   return;
//   // }
//   // const save = node.parent;
//   // if (!is(save)) {
//   //   return;
//   // }
//   // await appNodeSaveNext.call(save);
//   // if (isWait(status)) {
//   //   if (!is(progress) || progress === 0.8) {
//   //     output.progress = 0.6;
//   //   } else {
//   //     output.progress = progress + 0.1;
//   //   }
//   //   void delay(async () => {
//   //     await doSaveNext.call(node);
//   //   });
//   //   AppState.current.refresh();
//   //   return;
//   // }
//   // output.setOk();
//   AppState.current.refresh();
// }
