import { is, AiosStatus, isFail } from 'aios';
import { type AiosNode } from '../../AiosNode';
import { getAiosNodeAction, setAiosNodeAction } from 'app/AiosNode/AiosNodeApi';
import { removeNodeData } from './removeNodeData';

export async function removeNodeLast(node: AiosNode): Promise<boolean | undefined> {
  const { progress } = getAiosNodeAction(node);
  if (!is(progress) || progress < 0.7) {
    setAiosNodeAction(node, { text: 'removing last', status: AiosStatus.Processing, progress: 0.7 });
    return undefined;
  }
  if (progress < 0.8) {
    setAiosNodeAction(node, { text: 'removing nodes', status: AiosStatus.Processing, progress: 0.8 });
    const { nodes } = node;
    if (is(nodes)) {
      let anyUndefined = false;
      for (let i = 0; i < nodes.length; i++) {
        const child = nodes[i];
        const childRemoved = await removeNodeLast(child);
        if (childRemoved === false) {
          return false;
        }
        if (childRemoved === undefined) {
          anyUndefined = true;
        }
      }
      if (is(anyUndefined)) {
        setAiosNodeAction(node, { text: 'removing last', status: AiosStatus.Processing, progress: 0.7 });
        return undefined;
      }
    }
    setAiosNodeAction(node, { text: 'removing last data', status: AiosStatus.Processing, progress: 0.9 });
    return undefined;
  }
  if (progress < 0.9) {
    return undefined;
  }
  setAiosNodeAction(node, { text: 'removing last data', status: AiosStatus.Processing, progress: 0.9 });

  // todo: if (is(node.ui.selected)) {
  //  return
  // }

  await removeNodeData(node);
  const { status } = getAiosNodeAction(node);
  if (isFail(status)) {
    setAiosNodeAction(node, { text: 'removing last data', status: AiosStatus.Fail, progress: undefined });
    return false;
  }
  setAiosNodeAction(node, { text: 'removing last data', status: AiosStatus.Ok, progress: undefined });
  return true;
}
