import { is } from 'aios';
import { Grid10Row, Grid10CellButton, Grid10Cell } from 'components';
import { AppDataCode } from './AppDataCode';
import { AppDataForm } from './AppDataForm';
import { AppState } from 'signals/AppState/AppState';
import { type AppDataProps, type AppDataType } from './AppDataType';
import { getAiosNodeData, setAiosNodeData } from 'app/AiosNode/AiosNodeData';

export const AppDataBody: AppDataType = ({ node, view, onView, mode, onMode }: AppDataProps) => {
  const { assetFileText, jsonText } = getAiosNodeData(node);
  let diffText: string | undefined;
  let diffJsonText: string | undefined;
  // if (is(node.origin)) {
  //   const originData = getAiosNodeData(node.origin);
  //   diffText = originData.fileText;
  //   diffJsonText = originData.jsonText;
  // }
  const renderBody = (): JSX.Element => {
    if (view === 'data') {
      const onValue = (v?: string): void => {
        setAiosNodeData(node, { jsonText: v });
        AppState.current.refresh();
      };
      switch (mode) {
        case 'form':
          return (<AppDataForm node={node} />);
        case 'code':
          return (<AppDataCode type='json' diff={diffJsonText} mode={mode} value={jsonText as string} onValue={onValue} />);
        case 'diff':
          return (<AppDataCode type='json' diff={diffJsonText} mode={mode} value={jsonText as string} onValue={onValue} />);
      }
      return (<div>mode not supported</div>);
    }
    const onValue = (v?: string): void => {
      setAiosNodeData(node, { assetFileText: v });
      AppState.current.refresh();
    };
    switch (mode) {
      case 'code':
        return (<AppDataCode diff={diffText} mode={mode} value={assetFileText as string} onValue={onValue} />);
      case 'diff':
        return (<AppDataCode diff={diffText} mode={mode} value={assetFileText as string} onValue={onValue} />);
    }
    return (<div>mode not supported</div>);
  }
  return (
    <>
      <Grid10Row>
        <Grid10CellButton
          tip={'data view'}
          icon='data'
          onClick={async () => { is(onView) && onView('data') }}
          selected={view === 'data'}
        />
        <Grid10Cell spanx={8} spany={10} space>
          {renderBody()}
        </Grid10Cell>
        <Grid10CellButton
          tip={'form mode'}
          icon='form'
          onClick={async () => { is(onMode) && onMode('form') }}
          selected={mode === 'form'} />
      </Grid10Row>
      <Grid10Row>
        <Grid10CellButton
          tip={'file view'}
          icon='file'
          onClick={async () => { is(onView) && onView('file') }}
          selected={view === 'file'}
        />
        <Grid10CellButton
          tip={'code mode'}
          icon='code'
          onClick={async () => { is(onMode) && onMode('code') }}
          selected={mode === 'code'} />
      </Grid10Row>
      <Grid10Row>
        <Grid10Cell>&nbsp;</Grid10Cell>
        {!is(node.store) ? (<Grid10Cell>&nbsp;</Grid10Cell>) : (
          <Grid10CellButton
            tip={'diff mode'}
            icon='diff'
            onClick={async () => { is(onMode) && onMode('diff') }}
            selected={mode === 'diff'} />
        )}
      </Grid10Row>
      <Grid10Row>
        <Grid10Cell spany={7} space>&nbsp;</Grid10Cell>
        <Grid10Cell spany={7} space>&nbsp;</Grid10Cell>
      </Grid10Row>
    </>
  );
}
