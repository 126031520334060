import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { AppRoot } from './AppRoot';
import reportWebVitals from './reportWebVitals';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

const container = document.getElementById('root');
const root = createRoot(container as Element);
root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <AppRoot />
    </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
