import { useRef } from 'react';
import { type AiosPath, is, set } from 'aios';
import { getClass, useRoute } from 'signals';
import { GridCell } from './GridCell';
import { type GridCellIconProps, getIconProps } from './GridCellIcon';

export interface GridCellButtonProps extends GridCellIconProps {
  path?: AiosPath;
  onClick?: () => Promise<void>;
  onUpload?: (files: FileList) => Promise<boolean>;
  onDownload?: () => Promise<void>;
  downloadUrl?: string;
  downloadName?: string;
}

export const GridCellButton = (props: GridCellButtonProps): JSX.Element => {
  const { goTo } = useRoute();
  const { icon, color, blink } = getIconProps(props);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const onClick = (): void => {
    if (is(props.options?.disabled)) {
      return;
    }
    if (is(props.downloadUrl)) {
      doDownload();
      return;
    }
    if (is(props.onUpload)) {
      if (is(fileInputRef?.current)) {
        fileInputRef.current.click();
      }
      return;
    }
    if (is(props.onClick)) {
      void props.onClick();
    }
    if (is(props.path)) {
      if (is(props.path?.path) || is(props.path?.full)) {
        goTo(props.path)
      }
    }
  }
  const doUpload = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (!is(props.onUpload)) {
      return;
    }
    const handled = await props.onUpload(event.currentTarget.files as FileList);
    if (is(fileInputRef?.current)) {
      fileInputRef.current.value = '';
    }
    if (!is(handled) && is(props.path)) {
      goTo(props.path);
    }
  };
  const doDownload = (): void => {
    const { downloadUrl, downloadName } = props;
    if (!is(downloadUrl)) {
      return;
    }
    const download = async (): Promise<void> => {
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', set(downloadName, 'file'));
      document.body.appendChild(link);
      link.click();
      if (is(link.parentNode)) {
        link.parentNode.removeChild(link);
      }
    };
    void download();
  };
  const subOptions = {...props.options, border: false};
  return (
    <GridCell {...props}>
      <div className={getClass('grid-cell-button', {
        color: set(subOptions?.color, color),
        blink: set(subOptions?.blink, blink),
      })} onClick={onClick}>
        {is(props.icon) ? (
          <div style={{ width: is(props.name) ? '80%' : undefined }} className={getClass('grid-cell-icon', props.subOptions)}>
            {icon}
          </div>
        ) : (<></>)}
        {is(props.name) ? (
          <div className={getClass('grid-cell-text', props.subOptions)}>
            <span data-testid={props.test}>{props.name}</span>
          </div>
        ) : (<></>)}
      </div>
      {is(props.onUpload) ? (
        <input
          hidden
          ref={fileInputRef}
          type='file'
          name={props.name}
          onChange={(e) => {
            void doUpload(e);
          }}
          data-test='file-upload'
        />
      ) : (<></>)}
    </GridCell>
  );
}
