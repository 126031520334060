import { type AppDataType, type AppDataProps } from './AppDataType';
import { type AiosNode, addAiosNodeCloneIndex, createAiosNodeChild, createAiosNodeClone, deleteAiosNodeChild, deleteAiosNodeClone } from 'app';
import { is, set, AiosType, createAiosPath, isWait, isOk } from 'aios';
import { useState } from 'react';
import { AppState } from 'signals/AppState/AppState';
import { Grid10 } from 'components';
import { AppDataHead } from './AppDataHead';
import { AppDataBody } from './AppDataBody';
import { setAiosNodeData } from 'app/AiosNode/AiosNodeData';

export const AppAssetNode: AppDataType = ({ node, child }: AppDataProps) => {
  const [view, setView] = useState('data');
  const [mode, setMode] = useState('form');
  const [more, setMore] = useState('less');
  if (!is(node)) {
    return (<div>no node</div>);
  }
  const { type, pathEx: path, nodes, clones, clonei } = node;
  const { action } = path;
  let icon = set(type as string, 'aios');
  if (!is(path)) {
    return (<div>no path</div>);
  }
  let spin;
  let onCreate;
  let onPath, onDelete, onClone;
  let text, onPrevious, onNext;
  let tick = false;
  let onTick;
  const onMode = (newMode: string): void => {
    setMode(newMode);
  };
  const onView = (newView: string): void => {
    setView(newView);
    if (newView === 'data') {
      setMode('form');
    } else {
      setMode('code');
    }
  };
  const onMore = async (): Promise<void> => {
    if (more === 'more') {
      setMore('less');
    } else {
      setMore('more');
    }
  };
  switch (action) {
    case 'create':
      onCreate = async (): Promise<void> => {
        createAiosNodeChild(node);
        AppState.current.refresh();
      };
      if (!is(child)) {
        onClone = async (): Promise<void> => {
          createAiosNodeClone(node);
          AppState.current.refresh();
        }
      } else {
        onPath = (value: string): void => {
          const path = createAiosPath({ path: value });
          setAiosNodeData(node, { path });
          AppState.current.refresh();
        };
        onDelete = async (): Promise<void> => {
          const parent = node.parent;
          if (is(parent)) {
            deleteAiosNodeChild(parent, node);
          }
          AppState.current.refresh();
        };
      }
      if (is(clones) && clonei !== undefined) {
        text = `${clonei + 1}/${clones.length}`;
        onDelete = async (): Promise<void> => {
          deleteAiosNodeClone(node);
          AppState.current.refresh();
        }
        onPrevious = async (): Promise<void> => {
          addAiosNodeCloneIndex(node, -1);
          AppState.current.refresh();
        }
        onNext = async (): Promise<void> => {
          addAiosNodeCloneIndex(node, 1);
          AppState.current.refresh();
        }
      }
      break;
    case 'delete':
      if (is(child)) {
        const status = node.api.action?.output?.status;
        if (!isWait(status)) {
          if (!isOk(status)) {
            if (is(node.ui.selected)) {
              tick = true;
            }
            onTick = (value: boolean): void => {
              const setTick = (n: AiosNode): void => {
                n.ui.selected = value;
                if (is(value) && is(n.nodes)) {
                  n.nodes.forEach((nn: AiosNode) => { setTick(nn) })
                }
              }
              setTick(node);
              AppState.current.refresh();
            };
          } else {
            icon = 'ok';
          }
        } else {
          icon = 'aios';
          spin = true;
        }
      }
      break;
  }
  let headView = (<></>);
  let bodyView = (<></>);
  let nodesView;
  headView = (
    <AppDataHead
      node={node}
      icon={icon}
      spin={spin}
      tick={tick}
      onTick={onTick}
      path={path.full}
      onPath={onPath}
      onDelete={onDelete}
      onPrevious={onPrevious}
      text={text}
      onNext={onNext}
      onClone={onClone}
      onCreate={onCreate}
      more={more}
      onMore={onMore}
    />);
  if (type === AiosType.Asset && action !== 'delete') {
    if (more === 'less') {
      bodyView = (
        <AppDataBody
          node={node}
          view={view}
          onView={(v: string) => { onView(v) }}
          mode={mode}
          onMode={(m: string) => { onMode(m) }}
        />
      );
    }
  }
  if (is(nodes)) {
    nodesView = [];
    for (let i = 0; i < nodes.length; i++) {
      const childNode = nodes[i];
      nodesView.push(<AppAssetNode key={`${i}-${childNode.path}`} node={childNode} child />);
    }
  }
  if (!is(child)) {
    return (
      <Grid10>
        {headView}
        {bodyView}
        {nodesView}
      </Grid10>
    );
  }
  return (
    <>
      {headView}
      {bodyView}
      {nodesView}
    </>
  );
};
