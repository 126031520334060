import type AiosFile from './AiosFile';
import { type AiosDataStore, createAiosDataStore } from '../AiosData';
import { AiosType } from '../AiosType';

export type AiosFileStore = AiosDataStore<AiosFile>;

export function createAiosFileStore(
  options?: Partial<AiosFileStore>,
): AiosFileStore {
  const service: AiosFileStore = createAiosDataStore({
    ...options,
    aiosType: AiosType.AssetFile,
  });
  return service;
}

export default createAiosFileStore;
