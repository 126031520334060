import Editor, { DiffEditor } from '@monaco-editor/react';
import { is, set } from 'aios';
import { ProgressBar } from 'components';
import { useApp } from 'signals';

export interface AppDataCodeProps {
  type?: string;
  mode?: string;
  diff?: string | undefined;
  value: string;
  onValue?: (value?: string) => void;
}

export const AppDataCode: (props: AppDataCodeProps) => JSX.Element = ({ type, mode, diff, value, onValue }: AppDataCodeProps) => {
  const { settings } = useApp();
  const theme = !is(settings.dark) ? 'vs-dark' : undefined;
  const onChange = (v: string | undefined): void => {
    if (is(onValue)) {
      onValue(v);
    }
  };
  if (mode === 'diff') {
    // eslint-disable-next-line
    const onMount = (editor: { getModifiedEditor: () => any; }): void => {
      const modifiedEditor = editor.getModifiedEditor();
      modifiedEditor.onDidChangeModelContent(() => {
        const newValue = modifiedEditor.getValue();
        onChange(newValue);
      });
    };
    return (
      <DiffEditor
        height='100%'
        language={set(type, 'typescript')}
        original={diff}
        modified={value}
        theme={theme}
        loading={<ProgressBar />}
        onMount={onMount}
      />
    )
  }
  return (
    <Editor
      height='100%'
      language={set(type, 'typescript')}
      value={value}
      theme={theme}
      loading={<ProgressBar />}
      onChange={onChange}
    />
  );
};
