import { useState } from 'react';
import { type AiosFile, is, getId } from 'aios';
import { GridCell, GridCellButton, GridRow } from 'components';

export interface AdminFileProps {
  file: AiosFile;
  count: number;
  index: number;
  onSelect: (file: AiosFile) => void;
}

export const AdminFile = ({ file, count, index, onSelect }: AdminFileProps): JSX.Element => {
  const [show, setShow] = useState('less');

  const onClick = async (): Promise<void> => {
    if (show === 'less') {
      setShow('more');
    } else {
      setShow('less');
    }
  };
  const renderFiles = (): JSX.Element => {
    const { files } = file;
    if (!is(files) || show === 'less') {
      return (<></>);
    }
    const nextIndex = index + 1;
    return (
      <>
        {files.map((f) => (
          <AdminFile
            key={`file-${file.path ?? getId()}`}
            file={f}
            index={nextIndex}
            count={count}
            onSelect={onSelect}
          />
        ))}
      </>
    );
    return (<></>);
  }

  return (
    <>
      <GridRow>
        {is(index) ? (<GridCell spanx={index}>&nbsp;</GridCell>) : (<></>)}
        <GridCellButton icon={show} onClick={onClick} />
        <GridCellButton name={file.path} spanx={count - index - 1} onClick={async () => { onSelect(file) }} />
      </GridRow>
      {renderFiles()}
    </>
  );
};
