import { type AiosAsset, AiosFileType, createAiosAsset, createAiosFile } from 'aios';
import { sampleJson, sampleMarkdown, sampleText } from './sampleText';
import { sampleCode } from './sampleCode';
import { sampleData } from './sampleData';
import { sampleImage } from './sampleImage';
import { sampleModel } from './sampleModel';
import { sampleVideo } from './sampleVideo';
import { sampleAudio } from './sampleAudio';

export function createAiosAssetSample(type: AiosFileType, path: string): AiosAsset {
  switch (type) {
    case AiosFileType.Container:
      return createAiosAsset({
        path: `${path}/container`,
        name: 'container',
        file: createAiosFile({
          type: AiosFileType.Container,
          path: `${path}/container`,
        }),
      });
    case AiosFileType.Content:
      return createAiosAsset({
        path: `${path}/content.md`,
        name: 'content.md',
        file: createAiosFile({
          type: AiosFileType.Content,
          path: `${path}/content.md`,
          text: sampleMarkdown,
          mime: 'application/markdown'
        }),
      });
    case AiosFileType.Code:
      return createAiosAsset({
        path: `${path}/code.ts`,
        name: 'code.ts',
        file: createAiosFile({
          type: AiosFileType.Code,
          path: `${path}/code.ts`,
          text: sampleCode,
          mime: 'application/typescript'
        }),
      });
    case AiosFileType.Data:
      return createAiosAsset({
        path: `${path}/data.dat`,
        name: 'data.dat',
        file: createAiosFile({
          type: AiosFileType.Data,
          path: `${path}/data.dat`,
          base: sampleData,
          mime: ''
        }),
      });
    case AiosFileType.Text:
      return createAiosAsset({
        path: `${path}/text.txt`,
        name: 'text.txt',
        file: createAiosFile({
          type: AiosFileType.Text,
          path: `${path}/text.txt`,
          text: sampleText,
          mime: 'text/plain'
        }),
      });
    case AiosFileType.Json:
      return createAiosAsset({
        path: `${path}/json.json`,
        name: 'json.json',
        file: createAiosFile({
          type: AiosFileType.Json,
          path: `${path}/json.json`,
          text: sampleJson,
          mime: 'application/json'
        }),
      });
    case AiosFileType.Image:
      return createAiosAsset({
        path: `${path}/image.png`,
        name: 'image.png',
        file: createAiosFile({
          type: AiosFileType.Image,
          path: `${path}/image.png`,
          base: sampleImage,
          mime: 'image/png',
        }),
      });
    case AiosFileType.Audio:
      return createAiosAsset({
        path: `${path}/sound.mp3`,
        name: 'sound.mp3',
        file: createAiosFile({
          type: AiosFileType.Audio,
          path: `${path}/sound.mp3`,
          base: sampleAudio,
          mime: 'audio/mpeg',
        }),
      });
    case AiosFileType.Video:
      return createAiosAsset({
        path: `${path}/video.mp4`,
        name: 'video.mp4',
        file: createAiosFile({
          type: AiosFileType.Video,
          path: `${path}/video.mp4`,
          base: sampleVideo,
          mime: 'video/mp4',
        }),
      });
    case AiosFileType.Model:
      return createAiosAsset({
        path: `${path}/model.gltf`,
        name: 'model.gltf',
        file: createAiosFile({
          type: AiosFileType.Model,
          path: `${path}/model.gltf`,
          text: sampleModel,
          mime: 'application/gltf',
        }),
      });
  }
  return createAiosAsset({
    path: `${path}/text.txt`,
    name: 'text.txt',
    file: createAiosFile({
      type: AiosFileType.Text,
      path: `${path}/text.txt`,
      text: 'this is text',
    }),
  });
}
