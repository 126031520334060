import { type AiosCache, type AiosCacheItem, type AiosData, is } from 'aios';
import { type IDBPDatabase, openDB } from 'idb';

let cache: AiosCache | undefined;
let indexedDb: IDBPDatabase<unknown> | undefined;

const openIdb = openDB('prodev', 1, {
  upgrade(db) {
    if (!db.objectStoreNames.contains('items')) {
      db.createObjectStore('items', { keyPath: 'key' });
    }
  },
});

export function createAppCache(): AiosCache {
  if (!is(cache)) {
    cache = {
      clr: async function (this: AiosCache): Promise<void> {
        try {
          if (!is(indexedDb)) {
            indexedDb = await openIdb;
          }
          await indexedDb.clear('items');
        } catch (e) {
          console.log(e);
        }
      },
      set: async function (this: AiosCache, key: string, value: AiosData): Promise<void> {
        try {
          if (!is(indexedDb)) {
            indexedDb = await openIdb;
          }
          const item: AiosCacheItem = { key, date: new Date(), value };
          await indexedDb.put('items', item);
        } catch (e) {
          console.log(e);
        }
      },
      get: async function (this: AiosCache, key: string): Promise<AiosData | undefined> {
        try {
          if (!is(indexedDb)) {
            indexedDb = await openIdb;
          }
          const item = await indexedDb.get('items', key) as AiosCacheItem;
          return item?.value;
        } catch (e) {
          console.log(e);
        }
      },
      rem: async function (this: AiosCache, key: string): Promise<void> {
        try {
          if (!is(indexedDb)) {
            indexedDb = await openIdb;
          }
          await indexedDb.delete('items', key);
        } catch (e) {
          console.log(e);
        }
      },
    };
  }
  return cache;
}
