import { useApp, useRoute, getClass, appSignal } from 'signals';
import { is } from 'aios';
import { Grid10 } from 'components';
import { AppActionFoot } from './AppActionFoot';
import { AppActionItem } from './AppActionItem';
import { findAiosNode } from 'app';
import { getAiosNodeAction } from 'app/AiosNode/AiosNodeApi';

export const AppAction = (): JSX.Element => {
  const { root } = useApp();
  const { path } = useRoute();
  if (path.path === '' || path.path === '/') {
    return (
      <div>AppActino no path</div>
    );
  }
  const node = findAiosNode(root, path);
  if (!is(node)) {
    return (
      <div>AppActino no node</div>
    );
  }
  let className = getClass('app-action');
  if (!is(appSignal.value.indexMenu)) {
    className = `${className} wide`;
  }
  const { action, actions } = getAiosNodeAction(node);
  if (!is(action) && !is(actions)) {
    <div>AppActino no action</div>
  }
  return (
    <div className={className}>
      <div className='app-action-container'>
        <div className='app-action-controls'>
          <AppActionFoot />
          <Grid10>
            {
              is(actions) ?
                actions.map((a, i) => (
                  <AppActionItem index={`${i === actions.length - 1 ? '1' : ''}`} action={a} key={`app-action-${i}`} />
                )) :
                (<></>)
            }
            {
              is(action) ?
                <AppActionItem action={action} index='0' /> :
                (<></>)
            }
          </Grid10>
        </div>
      </div>
    </div>
  );
};
