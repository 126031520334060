import React from 'react';
import './Foot.css';

export interface FooterProps {
  children?: React.ReactNode;
}
export const Foot = ({ children }: FooterProps): JSX.Element => {
  return (
    <div className='foot'>
      {children}
    </div>
  );
};
