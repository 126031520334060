import { type AiosData, createAiosData, set } from '../AiosUtil';

export interface AiosAccess extends AiosData {
  actorId?: string;
  read?: boolean;
  run?: boolean;
}

export function createAiosAccess(options?: Partial<AiosAccess>): AiosAccess {
  const base = createAiosData(options);
  const access: AiosAccess = {
    ...base,
    actorId: set(options?.actorId, undefined),
    read: set(options?.read, undefined),
    run: set(options?.run, undefined),
  };
  return access;
}
