import { is } from './is';

export async function sleep(ms?: number): Promise<void> {
  if (!is(ms)) {
    ms = 1;
  }
  await new Promise((resolve) => setTimeout(resolve, ms, ms));
}

export async function delay(
  func: () => Promise<void>,
  ms?: number,
): Promise<void> {
  await sleep(ms);
  await func();
}

// export function sleepOrCancel(milliseconds?: number): {
//   sleep: Promise<boolean>;
//   cancel: () => void;
// } {
//   if (!is(milliseconds)) {
//     milliseconds = 1;
//   }
//   let timeoutId: ReturnType<typeof setTimeout> | null = null;
//   let cancel: () => void = () => {};
//   const sleep = new Promise<boolean>((resolve) => {
//     timeoutId = setTimeout(() => resolve(true), milliseconds);
//     cancel = () => {
//       if (timeoutId) {
//         clearTimeout(timeoutId);
//         timeoutId = null;
//         resolve(false);
//       }
//     };
//   });
//   return { sleep, cancel };
// }

// export function delayOrCancel(
//   func: () => Promise<void>,
//   milliseconds?: number,
// ): { delay: () => Promise<boolean>; cancel: () => void } {
//   if (!is(milliseconds)) {
//     milliseconds = 1;
//   }
//   let timeoutId: ReturnType<typeof setTimeout> | null = null;
//   let isCanceled = false;
//   const delay = (): Promise<boolean> => {
//     return new Promise<boolean>((resolve) => {
//       timeoutId = setTimeout(async () => {
//         if (!isCanceled) {
//           try {
//             await func();
//             resolve(true);
//           } catch (error) {
//             console.error('Error executing function:', error);
//             resolve(false);
//           }
//         }
//       }, milliseconds);
//     });
//   };
//   const cancel = () => {
//     if (timeoutId) {
//       clearTimeout(timeoutId);
//       timeoutId = null;
//       isCanceled = true;
//     }
//   };

//   return { delay, cancel };
// }

export default sleep;
