import { useState, useRef, useEffect } from 'react';
import { is, isFail, isWait } from 'aios';
import Form from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import { type ErrorSchema } from '@rjsf/utils';
import { type IChangeEvent } from '@rjsf/core';
import { useRoute } from 'signals';
import { AppState } from 'signals/AppState/AppState';
import { type AppDataType, type AppDataProps } from './AppDataType';
import { getFormSchema } from './getFormSchema';
import { getAiosNodeData, setAiosNodeData } from 'app/AiosNode/AiosNodeData';
import { setAiosNodeUi } from 'app/AiosNode/AiosNodeUi';
import { getAiosNodeAction } from 'app/AiosNode/AiosNodeApi';
import { doSave, restoreAiosNode } from 'app';

export const AppDataForm: AppDataType = ({ node, disabled }: AppDataProps) => {
  const { path } = useRoute();
  const ref = useRef<HTMLButtonElement>(null);
  const { status } = getAiosNodeAction(node);
  // const [current, setCurrent] = useState(status);
  const [extraErrors, setExtraErrors] = useState<ErrorSchema>({});
  useEffect(() => {
    if (isFail(status)) {
      const fields = node.api.action?.output?.fields;
      if (is(fields)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const fieldErrors: ErrorSchema<any> = {};
        const entries = Object.entries(fields);
        entries.forEach((fieldError) => {
          const [fkey, fmessage] = fieldError;
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          fieldErrors[fkey] = {
            __errors: [fmessage],
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } as ErrorSchema<any>;
        });
        setExtraErrors(fieldErrors);
      }
    }
    // setCurrent(status);
  }, [status]);
  if (!is(node)) {
    return <div>AppDataItemForm no node</div>;
  }
  const { item } = getAiosNodeData(node);
  if (!is(item)) {
    return <div>no item</div>;
  }
  const onChange = ({ formData }: IChangeEvent): void => {
    setAiosNodeData(node, { item: formData });
    AppState.current.refresh();
  };
  const onSubmit = ({ formData }: IChangeEvent): void => {
    setAiosNodeData(node, { item: formData });
    void doSave(node);
    // if (isWait(status)) {
    //   return;
    // }
    // setAiosNodeData(node, { item: formData });
    // setAiosNodeAction(node, { text: 'saving', status: AiosStatus.Processing });
    // void delay(async (): Promise<void> => {
    //   await saveNode(node);
    //   const { action: saveAction, output: saveResult } = getAiosNodeAction(node);
    //   if (!is(saveResult)) {
    //     return;
    //   }
    //   // THIS HAS TO MOVE
    //   if (isFail(saveResult.status)) {
    //     const fields = saveResult.fields;
    //     if (is(fields)) {
    //       // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //       const fieldErrors: ErrorSchema<any> = {};
    //       const entries = Object.entries(fields);
    //       entries.forEach((fieldError) => {
    //         const [fkey, fmessage] = fieldError;
    //         // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    //         fieldErrors[fkey] = {
    //           __errors: [fmessage],
    //           // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //         } as ErrorSchema<any>;
    //       });
    //       setExtraErrors(fieldErrors);
    //     } else {
    //       addAiosNodeAction(node, saveAction);
    //     }
    //     AppState.current.refresh();
    //     return;
    //   }
    //   const returnPath = node?.pathEx;
    //   if (is(returnPath)) {
    //     goTo(returnPath);
    //   }
    // });
    // AppState.current.refresh();
  };
  if (!isWait(status) && is(node.ui) && !is(node.ui.doSubmit)) {
    setAiosNodeUi(node, {
      ...node.ui,
      doReset: () => {
        restoreAiosNode(node);
        AppState.current.refresh();
      },
      doSubmit: async () => {
        const current = ref?.current;
        if (is(current)) {
          current.click();
        }
      }
    });
  }
  const schema = getFormSchema(path);
  return (
    <div style={{ padding: '20px', height: '100%', overflowY: 'auto' }}>
      <div>
        <Form
          schema={schema}
          formData={item}
          validator={validator}
          onChange={onChange}
          onSubmit={onSubmit}
          extraErrors={extraErrors}
          disabled={disabled}
        >
          <button ref={ref} type='submit' style={{ display: 'none' }} />
        </Form>
      </div>
    </div>
  );
};