import { type AppDataProps, type AppDataType } from './AppDataType';

// todo: export const AppContentListAction: AppControlType = ({ node }: AppControlProps) => {
//   let data = node.data;
//   if (!is(data)) {
//     data = node.parent?.data;
//     if (!is(data)) {
//       return <div>no data</div>;
//     }
//   }
//   const types = [];
//   const items: AiosData[] = [];
//   types.push({
//     title: 'id',
//     field: 'id',
//     size: 225,
//   });
//   types.push({
//     title: 'type',
//     field: 'type',
//     size: 75
//   });
//   types.push({
//     title: 'account',
//     field: 'path',
//     size: 225
//   });
//   if (is(data.items)){
//     data.items.forEach(d => items.push({ id: d?.path }))
//   }  
//   return (
//     <List types={types} items={items} />
//   );
// };

export const AppDataList: AppDataType = ({ node }: AppDataProps) => {
  return <></>;
  // const { path, goTo } = useRoute();
  // let data = node.data;
  // if (!is(data)) {
  //   data = node.parent?.data;
  //   if (!is(data)) {
  //     return <div>no data</div>;
  //   }
  // }
  // const { item } = data;
  // const types = [];
  // const items = [];
  // const asset = item as AiosAsset;
  // if (!is(asset)) {
  //   return <div>no items</div>;
  // }
  // const { versions, accesses } = asset;
  // if (path.mode !== 'access') {
  //   types.push({
  //     title: 'id',
  //     field: 'id',
  //     size: 500,
  //   });
  //   if (is(versions)) {
  //     for (let i = 0; i < versions.length; i++) {
  //       const version = versions[i];
  //       const { path } = version;
  //       items.push({ id: path });
  //     }
  //   }
  // }
  // else {
  //   types.push({
  //     title: 'id',
  //     field: 'id',
  //     size: 225,
  //   });
  //   types.push({
  //     title: 'account',
  //     field: 'path',
  //     size: 225
  //   });
  //   types.push({
  //     title: 'read',
  //     field: 'read',
  //     size: 75
  //   });
  //   types.push({
  //     title: 'run',
  //     field: 'run',
  //     size: 75
  //   });
  //   if (is(accesses)) {
  //     for (let i = 0; i < accesses.length; i++) {
  //       const access = accesses[i];
  //       const { id, path, read, run } = access;
  //       const accessPath = createAiosPath({ full: path, mode: 'access', modeId: id });
  //       items.push(
  //         {
  //           id: accessPath.full,
  //           path: accessPath.full,
  //           read,
  //           run
  //         });

  //     }
  //   }
  // }
  // const onSelect = (id: string): void => {
  //   const path = createAiosPath({ path: id });
  //   goTo(path);
  // }
  // return (
  //   <List types={types} items={items} onSelect={onSelect} />
  // );
};