export enum AiosType {
  None = '',
  Aios = 'Aios',
  Auth = 'Auth',
  Account = 'Account',
  Asset = 'Asset',
  AssetFile = 'AssetFile',
  Access = 'Access',
  Action = 'Action',
  ActionChat = 'ActionChat',
  ActionCode = 'ActionCode',
  ServerMail = 'ServerMail',
  ServerTask = 'ServerTask',
  AiosNodeRoot = 'AiosNodeRoot',
  AiosNodeAios = 'AiosNodeAios',
  AiosNodeUser = 'AiosNodeUser',
}
