import { type ReactNode } from 'react';
import './Page.css';

export interface PageProps {
  children: ReactNode;
}

export const Page = ({ children }: PageProps): JSX.Element => {
  return <div className='page'>{children}</div>;
};
