import { type AiosAuth } from '../AiosAuth';
import {
  type AiosData,
  type AiosDataInput,
  type AiosDataOutput,
  type AiosException,
  type AiosPath,
  AiosStatus,
  anon,
  createAiosDataOutput,
  createAiosPath,
  is,
  set,
} from '../AiosUtil';

export interface AiosClient {
  url: string;
  auth: AiosAuth;
  token?: string;
  send: AiosClientSendFunction;
  cancel: () => void;

  // urlEx: string;
  // isConnected: boolean;
  // connect: () => boolean;
  // disconnect: () => void;
  // sendEx: (command: AiosCommand) => void;
  // onOpen?: () => void;
  // onClose?: (error?: string) => void;
  // onReceive?: (command: AiosCommand) => void;
  // webSocket?: WebSocket;
}

export interface AiosClientSendFunctionInput {
  path: AiosPath;
  input: AiosDataInput<AiosData>;
}

export type AiosClientSendFunction = (
  input: AiosClientSendFunctionInput,
) => Promise<AiosDataOutput<AiosData>>;

export function createAiosClient(options?: Partial<AiosClient>): AiosClient {
  const controller = new AbortController();
  const client: AiosClient = {
    url: set(options?.url, 'https://prodev.ai/'),
    auth: set(options?.auth, { id: anon }),
    send: set(
      options?.send,
      async function (
        this: typeof client,
        { path, input }: AiosClientSendFunctionInput,
      ) {
        let url = path?.full as string;
        if (!is(url)) {
          url = createAiosPath(path).path as string;
        }
        if (!is(url)) {
          url = this.url;
        } else {
          url = `${this.url}/${url}`;
        }
        let output = createAiosDataOutput({
          text: `createAiosClient.send: ${url}`,
        });
        try {
          let body: string | undefined;
          if (is(input)) {
            body = JSON.stringify(input);
          }
          let authorization = set(this.auth?.headers?.authorization, '');
          if (is(authorization)) {
            if (!authorization.startsWith('Bearer ')) {
              authorization = `Bearer ${authorization}`;
            }
          }
          if (!is(authorization) && is(this.token)) {
            authorization = `Bearer ${this.token}`;
          }
          const request = {
            method: 'post',
            headers: { authorization },
            body,
            signal: controller.signal,
          };
          const response = await fetch(url, request);
          if (!response.ok) {
            if (response.status === 403) {
              output?.setFail({
                status: AiosStatus.FailAccess,
              });
              output?.setFail({
                text: `${response.status} - ${response.statusText}`,
              });
              return output;
            }
          }
          if (is(response.body)) {
            const result = await response.json();
            output = createAiosDataOutput(result);
          }
        } catch (exception) {
          output?.setException?.(exception as AiosException);
        }
        return output;
      },
    ),
    cancel: set(options?.cancel, function () {
      controller.abort();
    }),
    // urlEx: set(options?.urlEx, 'ws://localhost/'),
    // isConnected: set(options?.isConnected, false),
    // connect: set(options?.connect, function (this: AiosClient) {
    //   let result = 'createAionClient.connect';
    //   try {
    //     if (is(this.webSocket)) {
    //       this.disconnect();
    //     }
    //     this.webSocket = new WebSocket(this.url);
    //     // if (!this.webSocket) {
    //     //   resolve(false);
    //     // }
    //     // this.webSocket.addEventListener("open", () => {
    //     //   resolve(true);
    //     // });
    //     this.webSocket.addEventListener('open', () => {
    //       if (is(this.onOpen)) {
    //         this.onOpen();
    //       }
    //     });
    //     this.webSocket.addEventListener('close', (e: CloseEvent) => {
    //       if (is(this.onClose)) {
    //         this.onClose(`${e.code} ${e.reason}`);
    //       }
    //     });
    //     this.webSocket.addEventListener('error', (e: Event) => {
    //       console.log(e);
    //       // if (is(this.onClose)) {
    //       //   this.onClose();
    //       // }
    //     });
    //     this.webSocket.addEventListener('message', (event) => {
    //       if (is(this.onReceive)) {
    //         this.onReceive(createAiosCommand(JSON.parse(event.data)));
    //       }
    //     });
    //   } catch (exception) {
    //     result = `${result} - ${JSON.stringify(exception)}`;
    //   }
    //   // this.context.log(result);
    //   return false;
    // }),
    // disconnect: set(options?.disconnect, function (this: AiosClient) {
    //   let result = 'createAionClient.disconnect';
    //   try {
    //     if (is(this.webSocket)) {
    //       this.webSocket.close();
    //       delete this.webSocket;
    //     }
    //   } catch (exception) {
    //     result = `${result} - ${JSON.stringify(exception)}`;
    //   }
    //   // this.context.log(result);
    // }),
    // sendEx: set(
    //   options?.sendEx,
    //   function (this: AiosClient, command: AiosCommand) {
    //     let result = `createAionClient.send: ${JSON.stringify(command)}`;
    //     try {
    //       if (is(this.webSocket)) {
    //         this.webSocket.send(JSON.stringify(command));
    //       }
    //     } catch (exception) {
    //       result = `${result} - ${JSON.stringify(exception)}`;
    //     }
    //     // this.context.log(result);
    //   },
    // ),
    // onOpen: set(options?.onOpen, function (this: AiosClient) {
    //   let result = 'createAionClient.open';
    //   try {
    //     // await sleep(1);
    //   } catch (exception) {
    //     result = `${result} - ${JSON.stringify(exception)}`;
    //   }
    //   // this.context.log(result);
    // }),
    // onClose: set(options?.onClose, function (this: AiosClient) {
    //   let result = 'createAionClient.close';
    //   try {
    //     // await sleep(1);
    //   } catch (exception) {
    //     result = `${result} - ${JSON.stringify(exception)}`;
    //   }
    //   // this.context.log(result);
    // }),
    // onReceive: set(
    //   options?.onReceive,
    //   function (this: AiosClient, command: AiosCommand) {
    //     let result = `createAionClient.receive: ${JSON.stringify(command)}`;
    //     try {
    //       // await sleep(1);
    //     } catch (exception) {
    //       result = `${result} - ${JSON.stringify(exception)}`;
    //     }
    //     // console.log('CLIENT RECEIVE');
    //     // this.context.log(result);
    //   },
    // ),
  };
  return client;
}

export default AiosClient;
