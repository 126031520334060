import {
    type AiosAsset,
    AiosType,
    createAiosAccount,
    createAiosAsset,
    is,
    rem,
    add,
} from 'aios';
import { type AiosNode, createAiosNode } from './AiosNode';

export function storeAiosNode(node: AiosNode): void {
    node.store = createAiosNode();
    node.store = createAiosNodeClone(node);
}

export function restoreAiosNode(node: AiosNode): void {
    if (!is(node.store)) {
        return;
    }
    node.item = node.store.item;
    node.nodes = node.store.nodes;
}

export function createAiosNodeClone(node: AiosNode): AiosNode {
    if (!is(node.store)) {
        storeAiosNode(node);
    }
    const cloneNode = (cloneNode: AiosNode): AiosNode => {
        const cloned = createAiosNode(node);
        const { type, item, nodes } = cloned;
        if (is(item)) {
            switch (type) {
                case AiosType.Account:
                    cloned.item = createAiosAccount(item);
                    break;
                case AiosType.Asset:
                    cloned.item = createAiosAsset(item);
                    break;
            }
        }
        if (is(nodes)) {
            cloned.nodes = [];
            nodes.forEach(child => cloned.nodes?.push(createAiosNodeClone(child)));
        }
        return cloned;
    }
    const configureNode = (configue: AiosNode): void => {
        const { type, item, nodes } = configue;
        if (is(item) && is(nodes)) {
            switch (type) {
                case AiosType.Asset:
                    {
                        const asset = item as AiosAsset;
                        const assets: AiosAsset[] = [];
                        nodes.forEach(child => {
                            if (child.type === AiosType.Asset) {
                                const childAsset = child.item as AiosAsset;
                                if (is(childAsset)) {
                                    assets.push(childAsset);
                                }
                            }

                        });
                        asset.assets = assets;
                    }
                    break;
            }
        }
        if (is(nodes)) {
            nodes.forEach(child => { configureNode(child); });
        }
    }
    const clone = cloneNode(node);
    configureNode(clone);
    node.clones = add(node.clones, clone);
    if (node.clonei === undefined) {
        node.clonei = 0;
    } else {
        node.clonei = node.clonei + 1;
    }
    return clone;
}

export function deleteAiosNodeClone(node: AiosNode): void {
    let { clonei } = node;
    const { clones } = node;
    if (clonei === undefined || !is(clones)) {
        return;
    }
    if (clones.length === 1) {
        node.clonei = undefined;
        node.clones = undefined;
        restoreAiosNode(node);
        return;
    }
    const remove = clones[clonei];
    node.clones = rem(node.clones, remove);
    if (clonei > 0) {
        clonei = clonei - 1;
    }
    node.clonei = clonei;
    const clone = clones[clonei];
    node.item = clone.item;
    node.nodes = clone.nodes;
}

export function addAiosNodeCloneIndex(node: AiosNode, add: number): void {
    let { clonei } = node;
    const { clones } = node;
    if (!is(clones)) {
        return;
    }
    if (!is(clonei)) {
        clonei = 0;
    }
    clonei += add;
    if (clonei < 0) {
        clonei = clones.length - 1;
    } else {
        if (clonei >= clones.length) {
            clonei = 0;
        }
    }
    node.clonei = clonei;
    const clone = clones[clonei];
    node.item = clone.item;
    node.nodes = clone.nodes;
}