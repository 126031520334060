import {
  type AiosAccount,
  type AiosAuth,
  type AiosDataOutput,
  type AiosException,
  AiosStatus,
  AiosType,
  createAiosDataOutput,
  createAiosPath,
  is,
  sleep,
  isOk,
  set,
  add,
  isFail,
  isWait,
  delay,
  createAiosAuth,
  AiosDataCommand,
  createAiosDataInput,
  getId,
} from 'aios';
import { AppState } from 'signals/AppState/AppState';
import { createAiosNode, findAiosNode } from 'app';
import { Auth } from 'aws-amplify';
import { Hub, type HubCapsule } from '@aws-amplify/core';
import { appSignal } from 'signals';
import { createAiosClientApp, createAppCache } from 'utl';
import { addAiosNodeAction, getAiosNodeAction, setAiosNodeAction } from 'app/AiosNode/AiosNodeApi';

const useAws = true;

export class AuthState {
  static current: AuthState;

  constructor() {
    AuthState.current = this;
    if (is(useAws)) {
      Hub.listen('auth', (data: HubCapsule) => {
        if (data.payload.event === 'signIn') {
          void this.signIn();
        }
      });
    }
  }

  waiting = false;
  account?: AiosAccount;
  authResult = createAiosDataOutput<AiosAuth>();
  authAccountResult = createAiosDataOutput<AiosAccount>();
  signIn = async (): Promise<AiosAccount | boolean | undefined> => {
    if (is(this.account)) {
      return this.account;
    }
    const aios = findAiosNode(AppState.current.root, createAiosPath({ path: 'aios' }));
    if (!is(aios)) {
      return;
    }
    const { output: aiosOutput } = getAiosNodeAction(aios);
    let auth;
    this.waiting = true;
    if (!is(useAws)) {
      auth = await this.signInLocal();
    } else {
      auth = await this.signInAws();
    }
    if (!is(auth)) {
      setAiosNodeAction(aios, { text: 'logging in', status: AiosStatus.Processing, progress: 0.1 });
      return;
    }
    if (!isWait(aiosOutput?.status) || aiosOutput?.progress === 0.1) {
      setAiosNodeAction(aios, { text: 'logging in', status: AiosStatus.Processing, progress: 0.2 });
      AppState.current.refresh();
      void delay(async () => {
        await AuthState.current.signIn();
      }, 100);
      return;
    }
    if (aiosOutput?.progress === 0.2) {
      aiosOutput.progress = 0.3;
      const output = await this.signInAios(auth);
      aiosOutput.override(output);
      aiosOutput.text = 'logging in';
      if (isFail(this.authResult.status)) {
        this.reset();
        return false;
      }
      if (isOk(this.authAccountResult.status)) {
        this.account = this.authAccountResult.item;
        localStorage.setItem('aios_account_id', this.account?.id as string);
        if (this.account?.setting?.theme === 'Light') {
          appSignal.value.light = true;
          AppState.current.setSettings({ dark: true });
        } else {
          appSignal.value.light = false;
          AppState.current.setSettings({ dark: false });
        }
      } else {
        localStorage.removeItem('aios_account_id');
      }
      this.waiting = false;
      AppState.current.refresh();
    }
    return this.account;
  };

  signInCancel = (): void => {
    this.reset();
  };

  signInLocal = async (): Promise<AiosAuth | undefined> => {
    await sleep(1);
    const auth = {
      id: '',
      key: '',
      email: '',
      token: '',
    };
    return auth;
  };

  signInAws = async (): Promise<AiosAuth | undefined> => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (is(user)) {
        const id = user?.attributes?.sub as string;
        const authSession = await Auth.currentSession();
        const authorization = authSession.getAccessToken().getJwtToken();
        const auth = createAiosAuth({
          id,
          email: user.attributes.email,
          headers: {
            authorization: `Bearer ${authorization}`,
          }
        });
        return auth;
      }
    } catch { }
    return undefined;
  };

  signInAios = async (auth: AiosAuth): Promise<AiosDataOutput<AiosAuth>> => {
    const client = createAiosClientApp({ auth });
    const path = createAiosPath();
    const cacheId = set(localStorage.getItem('aios_cache_id'), getId());
    const cacheDate = localStorage.getItem('aios_cache_date');
    const input = createAiosDataInput({
      type: AiosType.Auth,
      item: {
        id: auth.id,
        email: auth.email,
        cacheId
      },
      command: AiosDataCommand.Auth,
    });
    const accountResult = await client.send({
      path,
      input,
    });
    const authResult = accountResult?.outputs?.[3] as AiosDataOutput<AiosAccount>;
    const updatedAuth = authResult?.item as AiosAuth;
    if (is(updatedAuth) && updatedAuth.lastSignIn !== cacheDate) {
      const cache = createAppCache();
      await cache.clr();
      localStorage.setItem('aios_cache_id', cacheId);
      localStorage.setItem('aios_cache_date', updatedAuth.lastSignIn as string);
    }    
    if (is(authResult) && is(accountResult)) {
      this.authResult.override(authResult);
      this.authAccountResult.override(accountResult);
      const path = createAiosPath({
        path: set(this.authAccountResult.item?.path, this.authAccountResult.item?.id),
      });
      const userNode = createAiosNode({
        pathEx: path,
        type: AiosType.Account,
        item: this.authAccountResult.item,
      });
      AppState.current.root.nodes = add(AppState.current.root.nodes, userNode);
    } else {
      this.authResult.setFail({ text: 'authorization failed' });
    }
    return authResult;
  };

  signOut = async (): Promise<boolean> => {
    this.waiting = true;
    this.authResult = createAiosDataOutput({
      text: 'signing out',
      status: AiosStatus.Processing,
    });
    try {
      if (!is(useAws)) {
        await this.signOutLocal();
      } else {
        await this.signOutAws();
      }
      this.reset();
      this.waiting = false;
      return true;
    } catch (exception) {
      this.authResult.setFail({ exception: exception as AiosException });
      this.waiting = false;
    }
    const aios = findAiosNode(AppState.current.root, createAiosPath({ path: 'aios' }));
    if (is(aios)) {
      const { action, output } = getAiosNodeAction(aios);
      output.setOk();
      addAiosNodeAction(aios, action);
      AppState.current.root.nodes = [aios];
      AppState.current.refresh();
    }
    this.waiting = false;
    return false;
  };

  signOutLocal = async (): Promise<void> => {
    await sleep(1);
  };

  signOutAws = async (): Promise<void> => {
    await Auth.signOut({ global: true });
  };

  reset = (): void => {
    this.waiting = false;
    this.account = undefined;
    localStorage.clear();
    const cache = createAppCache();
    void cache.clr();
  };

  refresh = (auth?: Partial<AuthState>): void => {
    console.log('AuthState refresh not implemented');
    console.log(auth);
  };
}
