import { appSignal, getClass } from 'signals';
import { is } from 'aios';
import './Grid.css';

export interface GridProps {
  rows?: number;
  cols?: number;
  cols0?: number;
  cols1?: number;
  cols2?: number;
  width?: number;
  height?: number;
  colHeight?: number;
  float?: boolean;
  right?: boolean;
  center?: boolean;
  children?: React.ReactNode;
}

export const Grid = ({ rows, cols, cols0, cols1, cols2, width, height, colHeight, float, right, center, children }: GridProps): JSX.Element => {
  const style = {
    height: '',
    width: '',
    gridTemplateColumns: '',
    gridTemplateRows: '',
    gridAutoRows: !is(colHeight) ? '' : `${colHeight}vh`,
    gridAutoCols: ''
  };
  let square = '';
  if (is(height)) {
    if (!is(rows)) {
      square = `${height}vh`;
    } else {
      square = `${height / rows}vh`;
    }
  }
  if (is(width)) {
    if (!is(square) || is(appSignal.value.narrow)) {
      if (!is(cols)) {
        square = `${width}vw`;
      } else {
        square = `${width / cols}vw`;
      }
    }
  }
  if (is(cols0) && is(cols1) && is(cols2)) {
    for (let i = 0; i < cols0; i++) {
      style.gridTemplateColumns = `${style.gridTemplateColumns} ${square}`;
    }
    for (let i = 0; i < cols1; i++) {
      style.gridTemplateColumns = `${style.gridTemplateColumns} 1fr`;
    }
    for (let i = 0; i < cols2; i++) {
      style.gridTemplateColumns = `${style.gridTemplateColumns} ${square}`;
    }
  } else {
    if (is(cols) && is(width)) {
      style.gridTemplateColumns = `repeat(${cols}, ${square})`;
    }
  }
  if (is(rows)) {
    style.gridTemplateRows = `repeat(${rows}, ${square})`;
  }
  let className = 'grid';
  if (is(float)) {
    className = `${className} float`
  }
  if (is(right)) {
    className = `${className} right`
  }
  if (is(center)) {
    className = `${className} center`
  }
  className = getClass(className);
  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
}