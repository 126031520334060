import React from 'react';
import { AuthContext } from './AuthContext';
import { type AuthState } from '../../signals/AppState/AuthState';

export function useAuth(): AuthState {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}
