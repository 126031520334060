import { type AiosPath, is, set } from 'aios';
import { getClass, useRoute } from 'signals';
import { Grid10Cell } from './Grid10Cell';
import { getGrid2IconProps, type Grid10CellIconProps } from './Grid10CellIcon';

export interface Grid10CellButtonProps extends Grid10CellIconProps {
  path?: AiosPath;
  indent?: string;
  onClick?: () => Promise<void>;
}

export const Grid10CellButton = (props: Grid10CellButtonProps): JSX.Element => {
  const { goTo } = useRoute();
  const { icon } = getGrid2IconProps(props);
  const onClick = (): void => {
    if (is(props.onClick)) {
      void props.onClick();
    }
    if (is(props.path)) {
      goTo(props.path)
    }
  }
  return (
    <Grid10Cell {...props}>
      <div className={getClass('grid10-cell-button')} onClick={onClick}>
        {is(props.icon) ? (
          <div style={{ width: is(props.name) ? '80%' : undefined }} className={getClass('grid10-cell-icon')}>
            {icon}
          </div>
        ) : (<></>)}
        {is(props.name) ? (
          <div className={getClass('grid10-cell-text')}>
            <span className={getClass('grid10-cell-span')} data-testid={props.test}>{`${set(props.indent, '')}${set(props.name, '')}`}</span>
          </div>
        ) : (<></>)}
      </div>
    </Grid10Cell>
  );
}
