import { Grid10, Grid10CellButton, Head } from 'components';
import { createAiosPath, is, isId, set } from 'aios';
import { useAuth, appSignal } from 'signals';
import { AppState } from 'signals/AppState/AppState';
import { useState } from 'react';
import { getConfig } from 'utl';
import { doSignIn, doSignOut } from 'app/funcs/load/loadNodeAiosFunctions';

export const AppHead = (): JSX.Element => {
  const { waiting, account } = useAuth();
  const [settings, setSettings] = useState(false);
  const display = getConfig('REACT_APP_AIOS_DNS');
  const accountString = !is(account) ? undefined : set(account.path, account.id);
  const indexToggle = async (): Promise<void> => {
    if (!is(appSignal.value.indexMenu)) {
      appSignal.value.indexMenu = true;
    } else {
      appSignal.value.indexMenu = false;
    }
    AppState.current.refresh();
  };
  const settingsToggle = async (): Promise<void> => {
    if (!is(settings)) {
      setSettings(true);
    } else {
      setSettings(false);
    }
  };

  if (is(settings)) {
    return (
      <Head>
        <Grid10>
          <Grid10CellButton tip='toggle index' icon='menu' onClick={indexToggle} />
          {!isId(accountString) ? (
            <Grid10CellButton spanx={7} name={`${display}/aios`} path={createAiosPath({ path: 'aios' })} />
          ) : (
            <Grid10CellButton spanx={7} name={`${display}/${accountString}`} path={createAiosPath({ path: `/${accountString}` })} />
          )}
          {!isId(accountString) ? (
            <Grid10CellButton tip='sign in' icon='login' onClick={doSignIn} disabled={waiting} />
          ) : (
            <Grid10CellButton tip='sign out' icon='logout' onClick={doSignOut} disabled={waiting} />
          )}
          {/* <Grid10CellButton tip='dark mode' icon='dark' selected={!appSignal.value.light} onClick={async () => {
            appSignal.value.light = false;
            AppState.current.refresh();
          }} />
          <Grid10CellButton tip='light mode' icon='light' selected={appSignal.value.light} onClick={async () => {
            appSignal.value.light = true;
            AppState.current.refresh();
          }} /> */}
          <Grid10CellButton tip='close' icon='close' onClick={settingsToggle} />
        </Grid10>
      </Head>
    );
  }
  return (
    <Head>
      <Grid10>
        <Grid10CellButton tip='toggle indev' icon='menu' onClick={indexToggle} />
        {!isId(accountString) ? (
          <Grid10CellButton spanx={8} name={`${display}/aios`} path={createAiosPath({ path: 'aios' })} />
        ) : (
          <Grid10CellButton spanx={8} name={`${display}/${accountString}`} path={createAiosPath({ path: `/${accountString}` })} />
        )}
        <Grid10CellButton tip='profile' icon='profile' onClick={settingsToggle} />
      </Grid10>
    </Head>
  );
};
