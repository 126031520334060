import { AppContainer } from 'AppContainer';
import { AppProvider, AiosProvider } from 'signals';

export const AppRoot = (): JSX.Element => {
  return (
    <AppProvider>
      <AiosProvider>
        <AppContainer />
      </AiosProvider>
    </AppProvider>
  );
};
