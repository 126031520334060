import { useState } from 'react';
import { type AiosAction, AiosType, createAiosObject, is, isFail, isWait, set } from 'aios';
import { Grid10Row, Grid10Cell, Grid10CellButton, Grid10CellIcon, Grid10CellText, ProgressBar } from 'components';
import { useApp } from 'signals';
import { Editor } from '@monaco-editor/react';
import { formatStatus } from './format';

export interface AppActionItemProps {
  action: AiosAction;
  index?: string;
}

export const AppActionItem = ({ action, index = '' }: AppActionItemProps): JSX.Element => {
  const { settings } = useApp();
  const [more, setMore] = useState('expand');
  const [mode, setMode] = useState('code');
  const theme = !is(settings.dark) ? 'vs-dark' : undefined;
  const { input, output } = action;
  const onMore = async (): Promise<void> => {
    if (more === 'collapse') {
      setMore('expand');
    } else {
      setMore('collapse');
    }
  };
  const renderHead = (): JSX.Element => {
    const space = (more === 'expand') ? true : undefined;
    // const icon = (output?.status === 'Processing') ? 'aios' : output;
    // const spin = (output?.status === 'Processing') ? true : undefined;
    // // if (!is(to) && !is(from)) {
    // //   return (
    // //     <Grid10Row>
    // //       <Grid10CellIcon icon={icon as string} space={space} spin={spin} />
    // //       <Grid10CellText spanx={7} space={space}>
    // //         <span>{input as string}</span>
    // //         <span> ... </span>
    // //         <span>{formatStatus(output as string)}</span>
    // //       </Grid10CellText>
    // //       {is(more === 'expand') ? (
    // //         <Grid10Cell space={space}>&nbsp;</Grid10Cell>
    // //       ) : mode === 'code' ? (
    // //         <Grid10CellButton tip='json' icon='json' onClick={async () => { setMode('json') }} />
    // //       ) : (
    // //         <Grid10CellButton tip='code' icon='code' onClick={async () => { setMode('code') }} />
    // //       )}
    // //       <Grid10CellButton icon={more} onClick={onMore} space={space} />
    // //     </Grid10Row>
    // //   )
    // // }
    const renderOutput = (): JSX.Element => {
      if (output?.type === AiosType.ActionChat){
        if (isWait(output?.status)) {
          return (
            <>
              <Grid10CellIcon space={space} icon='aios' spin />
              <Grid10CellText space={space} spanx={7}>
                <span> ... </span>
              </Grid10CellText>
            </>
          )
        }
        return (
          <>
            <Grid10CellIcon space={space} icon='aios' />
            <Grid10CellText space={space} spanx={7}>
              <span>{output?.item?.text}</span>
            </Grid10CellText>
          </>
        )
      }
      const icon = set(
        output?.item?.id
        , 'aios'
      );
      const text = set(
        output?.text,
        set(
          output?.item?.text,
          '')
      );
      if (isWait(output?.status)) {
        return (
          <>
            <Grid10CellIcon space={space} icon={icon} spin />
            <Grid10CellText space={space} spanx={7}>
              <span>{text}</span>
              <span> ... </span>
              <span data-testid={`result-${index}`}>{formatStatus(output?.status)}</span>
            </Grid10CellText>
          </>
        )
      }
      if (!isFail(output?.status)) {
        return (
          <>
            <Grid10CellIcon space={space} icon='ok' />
            <Grid10CellText space={space} spanx={7}>
              <span>{text}</span>
              <span> ... </span>
              <span data-testid={`result-${index}`}>{formatStatus(output?.status)}</span>
            </Grid10CellText>
          </>
        )
      }
      return (
        <>
          <Grid10CellIcon space={space} icon='fail' />
          <Grid10CellText space={space} spanx={7}>
            <span>{text}</span>
            <span> ... </span>
            <span data-testid={`result-${index}`}>{formatStatus(output?.status)}</span>
          </Grid10CellText>
        </>
      )
    }
    const inputText = set(input?.item?.text, '');
    return (
      <>
        {!is(inputText) ? (<></>) : (
          <Grid10Row>
            <Grid10Cell>&nbsp;</Grid10Cell>
            <Grid10CellText spanx={8} right>{inputText}</Grid10CellText>
            <Grid10CellIcon icon={input?.item?.id as string} />
          </Grid10Row>

        )}
        <Grid10Row>
          {renderOutput()}
          {is(more === 'expand') ? (
            <Grid10Cell space={space}>&nbsp;</Grid10Cell>
          ) : mode === 'code' ? (
            <Grid10CellButton tip='json' icon='json' onClick={async () => { setMode('json') }} />
          ) : (
            <Grid10CellButton tip='code' icon='code' onClick={async () => { setMode('code') }} />
          )}
          <Grid10CellButton icon={more} onClick={onMore} space={space} />
        </Grid10Row>
      </>
    );
    return (<></>);
  }
  const renderMore = (): JSX.Element => {
    if (more === 'expand') {
      return (<></>);
    }
    const actionObject = createAiosObject(action);
    const jsonObject = JSON.stringify(actionObject, undefined, 2);
    return (
      <Grid10Row>
        <Grid10Cell spanx={10} spany={4} space>
          {mode === 'code' ? (
            <div style={{
              height: '200px',
            }}>
              <Editor
                height='100%'
                loading={<ProgressBar />}
                value={jsonObject}
                theme={theme}
                options={{
                  readOnly: true,
                  lineNumbers: 'off',
                  minimap: {
                    enabled: false
                  }
                }}
              />
            </div>
          ) : (
            <div style={{
              height: '200px',
              padding: '10px',
              overflow: 'auto'
            }}>
              {/* <JsonDisplay json={jsonObject} /> */}
            </div>
          )}
        </Grid10Cell>
      </Grid10Row>
    );
  }
  return (
    <>
      {renderHead()}
      {renderMore()}
    </>
  );
};


