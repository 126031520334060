import { useApp, useRoute } from 'signals';
import { Foot, Grid10, Grid10Cell, Grid10Row } from 'components';
import { is } from 'aios';
import { findAiosNode } from 'app';
import { AppActionFootInput } from './AppActionFootInput';
import { getAiosNodeAction } from 'app/AiosNode/AiosNodeApi';

export const AppActionFoot = (): JSX.Element => {
  const { root } = useApp();
  const { path } = useRoute();
  if (path.path === '' || path.path === '/') {
    return (<div>AppFoot no path</div>);
  }
  const node = findAiosNode(root, path);
  if (!is(node)) {
    return (<div>AppFoot no node</div>);
  }
  const { action } = getAiosNodeAction(node);
  let chat = false;
  if (!is(action) && path.path === 'aios') {
    chat = true;
  }
  return (
    <Foot>
      <Grid10>
        {is(chat) ? (
          <AppActionFootInput node={node} />
        ) : (
          <Grid10Row>
            <Grid10Cell spanx={10} spany={2}>&nbsp;</Grid10Cell>
          </Grid10Row>
        )}
      </Grid10>
    </Foot>
  );
};