export const sampleModel = `{
  "asset": {
      "generator": "COLLADA2GLTF",
      "version": "2.0"
  },
  "extensionsUsed": [
      "KHR_materials_unlit"
  ],
  "extensionsRequired": [
      "KHR_materials_unlit"
  ],
  "scene": 0,
  "scenes": [
      {
          "nodes": [
              0
          ]
      }
  ],
  "nodes": [
      {
          "children": [
              1
          ],
          "matrix": [
              1.0,
              0.0,
              0.0,
              0.0,
              0.0,
              0.0,
              -1.0,
              0.0,
              0.0,
              1.0,
              0.0,
              0.0,
              0.0,
              0.0,
              0.0,
              1.0
          ]
      },
      {
          "mesh": 0
      }
  ],
  "meshes": [
      {
          "primitives": [
              {
                  "attributes": {
                      "NORMAL": 1,
                      "POSITION": 2
                  },
                  "indices": 0,
                  "mode": 4,
                  "material": 0
              }
          ],
          "name": "Mesh"
      }
  ],
  "accessors": [
      {
          "bufferView": 0,
          "byteOffset": 0,
          "componentType": 5123,
          "count": 36,
          "max": [
              23
          ],
          "min": [
              0
          ],
          "type": "SCALAR"
      },
      {
          "bufferView": 1,
          "byteOffset": 0,
          "componentType": 5126,
          "count": 24,
          "max": [
              1.0,
              1.0,
              1.0
          ],
          "min": [
              -1.0,
              -1.0,
              -1.0
          ],
          "type": "VEC3"
      },
      {
          "bufferView": 1,
          "byteOffset": 288,
          "componentType": 5126,
          "count": 24,
          "max": [
              0.5,
              0.5,
              0.5
          ],
          "min": [
              -0.5,
              -0.5,
              -0.5
          ],
          "type": "VEC3"
      }
  ],
  "materials": [
      {
          "pbrMetallicRoughness": {
              "baseColorFactor": [
                  0.0,
                  1.0,
                  0.0,
                  1.0
              ],
              "metallicFactor": 1.0
          },
          "name": "Unlit Green",
          "extensions": {
              "KHR_materials_unlit": {}
          }
      }
  ],
  "bufferViews": [
      {
          "buffer": 0,
          "byteOffset": 576,
          "byteLength": 72,
          "target": 34963
      },
      {
          "buffer": 0,
          "byteOffset": 0,
          "byteLength": 576,
          "byteStride": 12,
          "target": 34962
      }
  ],
  "buffers": [
      {
          "byteLength": 648,
          "uri": "data:application/octet-stream;base64,AAAAAAAAAAAAAIA/AAAAAAAAAAAAAIA/AAAAAAAAAAAAAIA/AAAAAAAAAAAAAIA/AAAAAAAAgL8AAAAAAAAAAAAAgL8AAAAAAAAAAAAAgL8AAAAAAAAAAAAAgL8AAAAAAACAPwAAAAAAAAAAAACAPwAAAAAAAAAAAACAPwAAAAAAAAAAAACAPwAAAAAAAAAAAAAAAAAAgD8AAAAAAAAAAAAAgD8AAAAAAAAAAAAAgD8AAAAAAAAAAAAAgD8AAAAAAACAvwAAAAAAAAAAAACAvwAAAAAAAAAAAACAvwAAAAAAAAAAAACAvwAAAAAAAAAAAAAAAAAAAAAAAIC/AAAAAAAAAAAAAIC/AAAAAAAAAAAAAIC/AAAAAAAAAAAAAIC/AAAAvwAAAL8AAAA/AAAAPwAAAL8AAAA/AAAAvwAAAD8AAAA/AAAAPwAAAD8AAAA/AAAAPwAAAL8AAAA/AAAAvwAAAL8AAAA/AAAAPwAAAL8AAAC/AAAAvwAAAL8AAAC/AAAAPwAAAD8AAAA/AAAAPwAAAL8AAAA/AAAAPwAAAD8AAAC/AAAAPwAAAL8AAAC/AAAAvwAAAD8AAAA/AAAAPwAAAD8AAAA/AAAAvwAAAD8AAAC/AAAAPwAAAD8AAAC/AAAAvwAAAL8AAAA/AAAAvwAAAD8AAAA/AAAAvwAAAL8AAAC/AAAAvwAAAD8AAAC/AAAAvwAAAL8AAAC/AAAAvwAAAD8AAAC/AAAAPwAAAL8AAAC/AAAAPwAAAD8AAAC/AAABAAIAAwACAAEABAAFAAYABwAGAAUACAAJAAoACwAKAAkADAANAA4ADwAOAA0AEAARABIAEwASABEAFAAVABYAFwAWABUA"
      }
  ]
}`;