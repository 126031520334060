import { type AiosNode, loadNode } from 'app';
import { is, isFail, isWait } from 'aios';
import { getAiosNodeAction } from 'app/AiosNode/AiosNodeApi';

export async function loadNodeNext(node: AiosNode): Promise<boolean | undefined> {  
  const { status } = getAiosNodeAction(node);
  if (!isFail(status)) {
    if (isWait(status)) {
      await loadNode(node);
      return undefined;
    }
    const { nodes } = node;
    if (is(nodes)) {
      try {
        // eslint-disable-next-line @typescript-eslint/promise-function-async
        const promises = nodes.map((child) => loadNodeNext(child));
        await Promise.all(promises);
        nodes.forEach(child => {
          const { status: childStatus } = getAiosNodeAction(child);
          if (isFail(childStatus)) {
            return false;
          }
          if (isWait(childStatus)) {
            return undefined;
          }
        });
      } catch (exception) {
        // output.setException(exception as AiosException);
      }
    }
    return true;
  }
  return false;
}