import { is } from 'aios';
import { Grid10CellIcon, Grid10Row, Grid10CellButton, Grid10CellInput, Grid10CellText, Grid10CellTick } from 'components';
import { type AppDataProps, type AppDataType } from './AppDataType';

export const AppDataHead: AppDataType = ({ 
  icon, spin, tick, onTick, path, onPath, onClone, onCreate, onDelete, onPrevious, text, onNext, more, onMore
}: AppDataProps) => {
  const buttons = [];
  if (is(onDelete)) {
    buttons.push(<Grid10CellButton key='head-delete' tip='delete' icon='delete' onClick={onDelete} />);
  }
  if (is(onPrevious)) {
    buttons.push(<Grid10CellButton key='head-previous' tip='previous' icon='left' onClick={onPrevious} />);
  }
  if (is(text)) {
    buttons.push(<Grid10CellText key='head-text' center>{text}</Grid10CellText>);
  }
  if (is(onNext)) {
    buttons.push(<Grid10CellButton key='head-next' tip='next' icon='right' onClick={onNext} />);
  }
  if (is(onClone)) {
    buttons.push(<Grid10CellButton key='head-clone' tip='clone' icon='clone' onClick={onClone} />);
  }
  if (is(onCreate)) {
    buttons.push(<Grid10CellButton key='head-create' tip='create' icon='create' onClick={onCreate} />);
  }
  if (is(onMore)) {
    buttons.push(<Grid10CellButton key='head-more' tip={more} icon={more} onClick={onMore} />);
  }
  const span = 9 - buttons.length;
  return (
    <Grid10Row>
      { !is(onTick) ? (
        <Grid10CellIcon icon={icon} spin={spin} />
      ) : (
        <Grid10CellTick value={tick} onChange={onTick} />
      ) }
      
      {!is(onPath) ? (
        <Grid10CellText spanx={span}>{path}</Grid10CellText>
      ) : (
        <Grid10CellInput spanx={span} value={path} onChange={onPath} />
      )}
      {buttons}
    </Grid10Row>
  );
};
