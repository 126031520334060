import {
  type AiosAction,  
  AiosDataCommand,
  createAiosPath,
  is,
  isOk,
  add,
} from 'aios';
import { createAiosNode, type AiosNode } from '../../AiosNode';
import { processNodeData, setAiosNodeAction } from 'app/AiosNode/AiosNodeApi';

export async function saveNodeData(node: AiosNode): Promise<AiosAction> {
  const action = await processNodeData(node, AiosDataCommand.Save);
  setAiosNodeAction(node, { action });
  return action;

  //   const { name, file } = item as { name: string; file: AiosFile };
  //   data.item = { ...apiOutput.item, file };
  //   node.id = apiOutput.item.id as string;
  //   node.name = is(name) ? name : (apiOutput.item.path as string);
  //   node.path = createAiosPath({
  //     aiosType: AiosType.Asset,
  //     path: apiOutput.item.path as string,
  //   });
  //   // await cache.save(node.path.full as string, data.item);
  // }
  // data.loaded = true;
  // data.changed = false;

  //   if (progress < 0.7) {
  //     setAiosNodeAction(node, { text: 'saving next', status: AiosStatus.Processing, progress: 0.7 });
  //     await saveNodeData(node);
  //     const { status: saveStatus } = getAiosNodeAction(node);
  //     if (isOk(saveStatus)) {
  //         if (is(node.nodes)) {
  //             setAiosNodeAction(node, { text: 'saving nodes', status: AiosStatus.Processing, progress: 0.2, waiting: true });
  //             void delay(async () => {
  //                 await saveNode(node);
  //             });
  //         } else {
  //             setAiosNodeAction(node, { text: 'saved node', status: AiosStatus.Ok, progress: undefined, waiting: false });
  //             const root = node.parent?.parent?.parent;
  //             const create = node.parent?.parent;
  //             if (is(root) && is(create)) {
  //                 const path = createAiosPath({ path: node.item?.path });
  //                 const newNode = createAiosNode({
  //                     parent: root,
  //                     type: node.type,
  //                     pathEx: path,
  //                     item: node.item,
  //                 });
  //                 setAiosNodeAction(newNode, { loaded: true });
  //                 // remove old set action
  //                 root.nodes = rem(root.nodes, create);
  //                 root.nodes = add(root.nodes, newNode);
  //                 AppState.current.goTo(path);
  //                 return;
  //             }
  //         }
  //     }
  //     AppState.current.refresh();
  //     return;
  // }

}
