import React from 'react';
import { getClass } from 'signals';
import './Head.css';

export interface HeadProps {
  children?: React.ReactNode;
}

export const Head = ({ children }: HeadProps): JSX.Element => {
  return (
    <div className={getClass('head')}>
      {children}
    </div>
  );
};
