import {
    type AiosAccount,
    type AiosAsset,
    AiosType,
    AiosFileType,
    createAiosAsset,
    createAiosFile,
    createAiosPath,
    is,
    add,
    rem
} from 'aios';
import { type AiosNode, createAiosNode } from './AiosNode';
import { createAiosAssetSample } from './sample';

export function createAiosNodeChild(node: AiosNode): AiosNode {
    const child = createAiosNode({ parent: node });
    const path = createAiosPath({ full: node.pathEx?.path });
    if (!is(path?.assetPath)) {
        child.type = AiosType.Asset;
        child.path = `${path?.path as string}/new?action=create`;
        child.pathEx = createAiosPath({ full: child.path });
        child.item = createAiosAsset({
            path: `${path?.path as string}/container`,
            name: 'container',
            file: createAiosFile({ type: AiosFileType.Container }),
        });
        const parentAccount = node.item as AiosAccount;
        if (is(parentAccount)) {
            parentAccount.assets = add(parentAccount.assets, child.item);
        }
    }
    else {
        const asset = createAiosAssetSample(AiosFileType.Code, path.path as string);
        child.type = AiosType.Asset;
        child.path = asset.path as string;
        child.pathEx = createAiosPath({ path: child.path });
        child.item = asset;
        const parentAsset = node.item as AiosAsset;
        if (is(parentAsset)) {
            parentAsset.assets = add(parentAsset.assets, child.item);
        }
    }
    node.nodes = add(node.nodes, child);
    return child;
}

export function deleteAiosNodeChild(node: AiosNode, child: AiosNode): void {
    const asset = node.item as AiosAsset;
    const childAsset = child.item as AiosAsset;
    if (is(asset) && is(childAsset)) {
        if (is(asset.assets)) {
            asset.assets = rem(asset.assets, childAsset);
        }
    }
    if (is(node.nodes)) {
        node.nodes = rem(node.nodes, child);
    }
}