import { type ReactNode, useState } from 'react';
import { type AiosNode } from 'app';
import { AiosType, getId, is, isWait } from 'aios';
import { Grid10CellButton, Grid10CellIcon, Grid10Row } from 'components';
import { getAiosNodeAction } from 'app/AiosNode/AiosNodeApi';
import { getIcon, getName } from 'app/AiosNode/AiosNodeData';

export interface AppIndexItemRowsProps {
  node: AiosNode;
  depth?: number;
}

export const AppIndexItem = ({ node, depth = 0 }: AppIndexItemRowsProps): JSX.Element => {
  let indent = '';
  if (is(depth)) {
    for (let i = 0; i < depth - 1; i++) {
      indent = `/ ${indent}`
    }
  }
  depth++;
  const [more, setMore] = useState('less');
  const onClick = async (): Promise<void> => {
    if (more === 'less') {
      setMore('more')
    } else {
      setMore('less')
    }
  }
  const items: ReactNode[] = [];
  const addItem = (item: AiosNode): void => {
    const { type, pathEx: path, nodes } = item;
    const { action } = path;
    const { status }= getAiosNodeAction(item);
    const spin = isWait(status);
    const icon = is(spin) ? 'aios' : getIcon(node);
    let test = icon === 'account' ? 'account-button' : path.assetPath;
    if (is(test)) {
      if (test.includes('/')) {
        test = test.replaceAll('/', '-');
      }
      if (test.includes('.')) {
        test = test.replaceAll('.', '-');
      }
    }
    if (type !== AiosType.AiosNodeRoot && !is(action)) {
      items.push(
        <Grid10Row key={getId()}>
          <Grid10CellIcon icon={icon} spin={spin} />
          {!is(nodes) ? (
            <Grid10CellButton spanx={9} test={test} name={getName(node)} path={path} indent={indent} />
          ) : (
            <>
              <Grid10CellButton spanx={8} test={test} name={getName(node)} path={path} indent={indent} />
              <Grid10CellButton tip={more} icon={more} onClick={onClick} />
            </>
          )}
        </Grid10Row>
      );
    }
    if (is(nodes) && more === 'less') {
      for (const child of nodes) {
        const action = child.pathEx?.action;
        if (is(action)) {
          continue;
        }
        items.push(
          <AppIndexItem
            key={getId()}
            node={child}
            depth={depth}
          />
        );
      }
    }
  }
  addItem(node);
  return (
    <>
      {items}
    </>
  );
};
