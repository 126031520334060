export function set<T>(
  setValue: T | undefined | null,
  defaultValue: T | undefined,
  createValue?: () => T,
): T {
  if (setValue !== undefined && setValue !== null) {
    return setValue;
  }
  if (createValue !== undefined && createValue !== null) {
    return createValue();
  }
  return defaultValue as T;
}

export default set;
