import {
  type AiosData,
  AiosStatus,
  AiosType,
  is,
  delay,
  isWait,
  AiosDataCommand,
  createAiosDataInput,
  createAiosAction,
  createAiosDataOutput,
  type AiosAsset,
  createAiosPath,
} from 'aios';
import { type AiosNode } from 'app';
import { addAiosNodeAction, getAiosNodeAction, setAiosNodeAction } from 'app/AiosNode/AiosNodeApi';
import { AppState } from 'signals/AppState/AppState';
import { createAiosClientApp } from 'utl';

export interface AiosAssetProcess {
  text: string;
  assets: AiosAsset[];
}

export async function doChat(node: AiosNode, input?: string): Promise<void> {
  if (!is(input)) {
    return;
  }
  input = embedTexts.call(node, input);
  setAiosNodeAction(node, {
    action: createAiosAction(
      {
        path: 'aios?action=chat',
        input: createAiosDataInput<AiosData>({
          type: AiosType.ActionChat,
          item: {
            id: 'user',
            text: input
          },
          command: AiosDataCommand.Chat
        }),
        output: createAiosDataOutput<AiosData>({
          type: AiosType.ActionChat,
          item: {
            id: 'aios',
            text: '...'
          },
          status: AiosStatus.Processing,
          progress: 0.1,
        }),
      }
    )
  });
  void delay(async () => {
    await doChatData(node);
  });
  AppState.current.refresh();
}

export async function doChatCancel(node: AiosNode): Promise<void> {
  const { action, output } = getAiosNodeAction(node);
  if (!is(output)) {
    return;
  }
  if (output.progress === 0.1) {
    output.setFail({ status: AiosStatus.FailCancel, text: 'cancelled' });
    addAiosNodeAction(node, action);
  }
  if (is(node.api.client)) {
    node.api.client.cancel();
    node.api.client = undefined;
  }
  AppState.current.refresh();
}

async function doChatData(node: AiosNode): Promise<void> {
  const { action, input, output } = getAiosNodeAction(node);
  if (!is(action) || !is(input) || !is(output)) {
    return;
  }
  if (!isWait(output.status)) {
    return;
  }
  output.progress = 0.2;
  const path = createAiosPath({ full: node.pathEx.full, action: 'chat' });
  node.api.client = createAiosClientApp();
  action.output = await node.api.client.send({ path, input });
  node.api.client = undefined;
  // if (is(action.output.item) && is(action.output.item.text)) {
  //   const json = action.output.item.text;
  //   const process: AiosAssetProcess = JSON.parse(json) as AiosAssetProcess;
  //   const { text, assets } = process;
  //   if (is(text)) {
  //     action.output.item.text = text;
  //   }
  //   if (is(assets)) {
  //     for (let i = 0; i < assets.length; i++) {
  //       const asset = assets[i];
  //       const file = asset?.file;
  //       const newAsset = createAiosAsset(asset);
  //       const newNode = createAiosNode({
  //         pathEx: createAiosPath({ path: set(asset.path, file?.path) }),
  //         type: AiosType.Asset,
  //         item: newAsset
  //       });
  //       node.nodes = add(node.nodes, newNode);
  //     }
  //   }
  // }
  addAiosNodeAction(node, action);
  setAiosNodeAction(node);
  AppState.current.refresh();
}

export async function doChatLoad(this: AiosNode): Promise<void> {
  // const path = createAiosPath({ path: 'aios/chat' });
  // const input = createAiosDataInput<AiosAction>({
  //   type: AiosType.ActionChat,
  //   command: AiosDataCommand.Load,
  // });
  // this.api.client = createAiosClientApp();
  // this.process = await this.api.client.send({ path, input });
  // const result = this.process.output;
  // const items = result?.items;
  // if (is(items)) {
  //   for (const item of items) {
  //     const chat = item as AiosAction;
  //     this.interface.showHistory({
  //       to: 'ai',
  //       from: 'you',
  //       text: chat.input,
  //     });
  //     this.interface.showHistory({
  //       to: 'you',
  //       from: 'ai',
  //       text: chat.output,
  //     });
  //   }
  // }
}

function embedTexts(this: AiosNode, input: string): string {

  // const { assetPath, fileText } = getAiosNodeData(this);
  // if (is(assetPath) && is(fileText)) {
  //   const files = {
  //     [assetPath]: fileText
  //   };
  //   // input = filesInject(input, files);
  // }

  return input;

  // const nodes = this.nodes;
  // if (is(nodes)) {
  //   for (const node of nodes) {
  //     embedText.call(node, input);
  //   }
  // }
  // return embedText.call(this, input);
}

// function embedText(this: AiosNode, input: string): string {
//   const asset = this.data?.item as AiosAsset;
//   if (!is(asset)) {
//     return input;
//   }
//   const name = asset.file?.path;
//   const text = asset.file?.text;
//   if (is(name) && is(text)) {
//     input = `
//       ${input}
//       >>>${name}<<<
//       ${text}
//       >>>${name}<<<
//       `;
//   }
//   return input;
// }

// function extractTexts(this: AiosNode, input?: string): string | undefined {
//   if (!is(input)) {
//     return input;
//   }
//   const parts = filesExtract(input);
//   const entries = Object.entries(parts);
//   for (const entry of entries) {
//     const [key, value] = entry;
//     if (key === 'response') {
//       input = value;
//     } else {
//       saveAiosAssetText.call(this, key, value);
//     }
//     // }
//     // const parts = extractText(input);
//     // const entries = Object.entries(parts);
//     // for (const entry of entries) {
//     //   const [key, value] = entry;
//     //   if (key === 'response') {
//     //     input = value;
//     //   } else {
//     //     saveAiosAssetText.call(this, key, value);
//     //   }
//   }
//   return input;
// }

// function extractText(text: string): Record<string, string> {
//   const delimiterRegex = />>>([^>]+?)<<<\n?([\s\S]+?)\n?>>>[^>]+?<<</g;
//   const contents: Record<string, string> = {};
//   const firstDelimiterIndex = text.search(/>>>[^>]+?<<</);
//   if (firstDelimiterIndex > 0) {
//     contents.response = text.substring(0, firstDelimiterIndex).trim();
//   } else if (firstDelimiterIndex === -1) {
//     return { response: text.trim() };
//   }
//   text = '\n' + text;
//   let match;
//   while ((match = delimiterRegex.exec(text)) !== null) {
//     const filename = match[1].trim().toLowerCase();
//     const content = match[2].trim();
//     contents[filename] = content;
//   }
//   return contents;
// }
