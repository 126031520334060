import { type ReactNode, useState } from 'react';
import { AppState } from '../../signals/AppState/AppState';
import { AppContext } from './AppContext';
import { is } from 'aios';

export interface AppProviderProps {
  children: ReactNode;
}

const appState = new AppState();

export const AppProvider = ({ children }: AppProviderProps): JSX.Element => {
  const [state, setState] = useState<AppState>(appState);
  state.refresh = (app?: Partial<AppState>) => {
    if (!is(app)){
      setState({ ...state });
    } else {
      setState({ ...state, ...app });
    }
  }
  return <AppContext.Provider value={state}>{children}</AppContext.Provider>;
};
