import { type AiosData, createAiosData, set } from '../AiosUtil';

export interface AiosAuthHeaders {
  ip?: string;
  origin?: string;
  authorization?: string;
}

export interface AiosAuth extends AiosData {
  email?: string;
  headers?: AiosAuthHeaders;
  cacheId?: string;
  lastSignIn?: string;
}

export function createAiosAuth(options?: Partial<AiosAuth>): AiosAuth {
  const base = createAiosData(options);
  const auth: AiosAuth = {
    ...base,
    email: set(options?.email, undefined),
    headers: set(options?.headers, undefined),
    cacheId: set(options?.cacheId, undefined),
    lastSignIn: set(options?.lastSignIn, undefined),
  };
  return auth;
}
