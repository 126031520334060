import { Body } from 'components';
import { AppIndex } from '../AppIndex';
import { AppAsset } from '../AppAsset';
import { AppAction } from '../AppAction';
import { getClass } from 'signals';

export const AppBody = (): JSX.Element => {
  const className = getClass('app-body');
  return (
    <Body>
      <div className={className}>
        <AppIndex />
        <AppAsset />
        <AppAction />
      </div>
    </Body>
  );
};
