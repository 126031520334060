import { type AiosFileType } from './AiosFileType';
import { is, set } from '../functions';
import { createAiosPath } from '..';
import { base64ToText } from '../../../utl';

export interface AiosFile {
  path?: string;
  type?: AiosFileType;
  text?: string;
  base?: string;
  mime?: string;
  size?: number;
  files?: AiosFile[];
}

export function createAiosFile(options?: Partial<AiosFile>): AiosFile {
  const file: AiosFile = {
    path: set(options?.path, undefined),
    type: set(options?.type, undefined),
    text: set(options?.text, undefined),
    base: set(options?.base, undefined),
    mime: set(options?.mime, undefined),
    size: set(options?.size, undefined),
    files: set(options?.files, undefined),
  };
  if (is(file.text)) {
    updateFromText(file, file.text);
    return file;
  }
  if (is(file.base)) {
    updateFromBase(file, file.base);
    return file;
  }
  return file;
}

export function updateAiosFile(
  file: AiosFile,
  options?: Partial<AiosFile>,
): AiosFile {
  if (!is(options)) {
    return file;
  }
  if (is(options.text) && file.text !== options.text) {
    updateFromText(file, options.text);
    return file;
  }
  if (is(options.base) && file.base !== options.base) {
    updateFromBase(file, options.base);
    return file;
  }
  return file;
}

export function toDataUrl(file: AiosFile): string {
  const { text, base, mime } = file;
  if (is(text)) {
    const encodedText = encodeURIComponent(text);
    if (is(mime)) {
      return `data:${mime},${encodedText}`;
    }
    return `data:text/plain,${encodedText}`;
  }
  if (is(base)) {
    if (is(mime)) {
      return `data:${mime};base64,${base}`;
    }
    return `data:application/octet-stream;base64,${base}`;
  }
  return 'data:text/plain;base64,unknown';
}

export function fromDataUrl(url: string): AiosFile {
  let text = '';
  let base = '';
  let mime = '';
  const parts = url.split(',');
  mime = parts.shift() as string;
  while (is(parts) && !is(mime)) {
    mime = parts.shift() as string;
  }
  base = parts.shift() as string;
  while (is(parts) && !is(base)) {
    base = parts.shift() as string;
  }
  if (is(mime)) {
    mime = mime.toLowerCase();
    if (mime.startsWith('data:')) {
      mime = mime.substring(5);
    }
    if (mime.endsWith(';base64')) {
      mime = mime.substring(0, mime.length - 7);
    }
    switch (mime) {
      case 'text/vnd.trolltech.linguist':
        mime = 'application/typescript';
        break;
    }
  }
  if (is(base)) {
    text = base64ToText(base);
  }
  return createAiosFile({ text, base, mime });
}

function updateFromText(file: AiosFile, text: string): void {
  file.text = text;
  file.base = undefined;
  file.size = text.length;
  const path = createAiosPath({ path: file.path });
  file.type = set(file.type, path.fileType);
  file.mime = set(file.mime, path.mimeType);
}

function updateFromBase(file: AiosFile, data: string): void {
  file.text = undefined;
  file.base = data;
  file.size = data.length;
  const path = createAiosPath({ path: file.path });
  file.type = set(file.type, path.fileType);
  file.mime = set(file.mime, path.mimeType);
}

export default AiosFile;
