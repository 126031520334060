import { type ReactNode } from 'react';

export interface Grid10RowProps {
  children?: ReactNode;
}

export const Grid10Row = ({ children }: Grid10RowProps): JSX.Element => {
  return (
    <>
      {children}
    </>
  );
}