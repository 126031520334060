import { is } from '..';

export enum AiosFileType {
  None = '',
  Container = 'Container',
  Content = 'Content',
  Code = 'Code',
  Data = 'Data',
  Text = 'Text',
  Json = 'Json',
  Image = 'Image',
  Audio = 'Audio',
  Model = 'Model',
  Video = 'Video',
}

export const isContainer = (type?: AiosFileType): boolean => {
  if (is(type)) {
    switch (type) {
      case AiosFileType.Container:
        return true;
    }
  }
  return false;
};

export const isFile = (type?: AiosFileType): boolean => {
  if (is(type)) {
    switch (type) {
      case AiosFileType.None:
      case AiosFileType.Container:
        return false;
    }
    return true;
  }
  return false;
};

export const isText = (type?: AiosFileType): boolean => {
  if (is(type)) {
    switch (type) {
      case AiosFileType.Content:
      case AiosFileType.Code:
      case AiosFileType.Text:
      case AiosFileType.Json:
        return true;
    }
  }
  return false;
};
