export function is<T>(value: T | undefined | null): value is T {
  if (value === undefined || value === null) {
    return false;
  }
  switch (typeof value) {
    case 'string':
      if (value === '') {
        return false;
      }
      break;
    case 'boolean':
      if (!value) {
        return false;
      }
      break;
    case 'number':
      if (value <= 0) {
        return false;
      }
      break;
    default:
      if (Array.isArray(value)) {
        if (
          value.length === 0 ||
          value.length === undefined ||
          value.length === null
        ) {
          return false;
        }
      }
      break;
  }
  return true;
}

export default is;
