import {
  AiosStatus,
  is,
  isFail,
  AiosType,
  add,
  createAiosAccount,
  createAiosPath,
  isWait,
  AiosAccountSchema,
} from 'aios';
import { createAiosNode, findAiosNode, type AiosNode } from '../../AiosNode';
import { addAiosNodeAction, getAiosNodeAction, setAiosNodeAction } from 'app/AiosNode/AiosNodeApi';
import { loadNodeData } from 'app/funcs/load/loadNodeData';
import { setAiosNodeUiData } from 'app/AiosNode/AiosNodeUi';
import { AppAssetNode } from 'views';
import { doReload } from './loadNodeAssetFunctions';
import { doRemoveCancel, doRemoveConfirm } from '../remove';
import { loadNodeNext } from './loadNodeNext';
import { AppState } from 'signals/AppState/AppState';

export async function loadNodeAccount(node: AiosNode): Promise<void> {
  if (!is(node.pathEx.action)) {
    await loadNodeAccountDefault(node);
    return;
  }
  await loadNodeAccountAction(node);
}

async function loadNodeAccountDefault(node: AiosNode): Promise<void> {
  const { status, progress, loaded } = getAiosNodeAction(node);
  if (is(loaded)) {
    setAiosNodeUiData(node, { functions: { create: undefined, delete: undefined } });
    return;
  }
  if (isFail(status)) {
    setAiosNodeUiData(node, { functions: { reload: doReload } });
    return;
  }
  if (!isWait(status)) {
    setAiosNodeAction(node, { text: 'loading account', status: AiosStatus.Processing, progress: 0.1 });
    setAiosNodeUiData(node, { functions: { cancel: undefined } });
    return;
  }
  if (progress === 0.1) {
    setAiosNodeAction(node, { text: 'loading account', status: AiosStatus.Processing, progress: 0.2 });
    await loadAccountDefaultData(node);
  }
  setAiosNodeUiData(node, { functions: { create: undefined, delete: undefined } });
}

async function loadAccountDefaultData(node: AiosNode): Promise<void> {
  const success = await loadNodeData(node);
  const { action } = getAiosNodeAction(node);
  addAiosNodeAction(node, action);
  setAiosNodeAction(node);
  if (!is(success)) {
    return;
  }
  const account = createAiosAccount(node.item);
  if (is(account?.assets)) {
    for (let i = 0; i < account.assets.length; i++) {
      const child = account.assets[i];
      let childPath = createAiosPath({ path: child.path });
      childPath = createAiosPath({
        path: `${node.pathEx?.path as string}/${childPath.name as string}`,
      });
      let childNode = findAiosNode(node, childPath);
      if (!is(childNode)) {
        childNode = createAiosNode();
        node.nodes = add(node.nodes, childNode);
      }
      childNode.parent = node;
      childNode.pathEx = childPath;
      childNode.type = AiosType.Asset;
      childNode.item = child;
    }
  }
}

async function loadNodeAccountAction(node: AiosNode): Promise<void> {
  const { action } = node.pathEx;
  const { progress } = getAiosNodeAction(node);
  if (action === 'create') {
    AppState.current.goTo(createAiosPath({ full: `${node.pathEx.path as string}/container?action=create` }));
    return;
  }
  if (action === 'update') {
    setAiosNodeUiData(node, { form: AppAssetNode, schema: AiosAccountSchema });
    setAiosNodeAction(node, { text: 'creating asset', status: AiosStatus.Ok, loaded: true });
    return;
  }
  if (action === 'delete') {
    const parent = node.parent as AiosNode;
    if (!is(progress) || progress < 0.1) {
      const deleteNode = createAiosNode({
        pathEx: node.pathEx,
        path: node.path,
        type: parent?.type,
        item: parent?.item,
        ui: {
          selected: true
        }
      });
      if (!is(node.nodes)) {
        node.nodes = add(node.nodes, deleteNode)
      }
      setAiosNodeAction(node, { text: 'staging', status: AiosStatus.Processing, progress: 0.1 });
      setAiosNodeUiData(node, { functions: { cancel: doRemoveCancel } });
      return;
    }
    if (progress < 0.2) {
      setAiosNodeAction(node, { text: 'stage nodes', status: AiosStatus.Processing, progress: 0.2 });
      setAiosNodeUiData(node, { functions: { cancel: doRemoveCancel } });
      return;
    }
    if (progress < 0.3) {
      setAiosNodeAction(node, { text: 'stage nodes', status: AiosStatus.Processing, progress: 0.3 });
      setAiosNodeUiData(node, { functions: { cancel: doRemoveCancel } });
      const staged = await loadNodeNext(parent);
      stageNodes(node, parent);
      if (staged === undefined) {
        setAiosNodeAction(node, { text: 'stage nodes', status: AiosStatus.Processing, progress: 0.2 });
        setAiosNodeUiData(node, { functions: { cancel: doRemoveCancel } });
        return;
      }
      if (!is(staged)) {
        setAiosNodeAction(node, { text: 'staging nodes', status: AiosStatus.Fail, progress: undefined });
        setAiosNodeUiData(node, { functions: { cancel: doRemoveCancel } });
        return;
      }
    }
    if (progress < 0.4) {
      setAiosNodeAction(node, { text: 'confirm delete', status: AiosStatus.Processing, progress: 0.4 });
      setAiosNodeUiData(node, { form: AppAssetNode, functions: { cancel: doRemoveCancel, confirm: doRemoveConfirm } });
      return;
    }
    if (progress < 1.0) {
      setAiosNodeUiData(node, { form: AppAssetNode, functions: { cancel: doRemoveCancel } });
      return;
    }
    setAiosNodeUiData(node, { form: AppAssetNode, functions: { ok: doRemoveCancel } });
    return;
  }
  setAiosNodeUiData(node, { functions: { reload: doReload } });
}

// action: "2a4e9608-cab6-bf5e-9b1e-29ebdd34bac5?action=delete"
// nodes[0]: "2a4e9608-cab6-bf5e-9b1e-29ebdd34bac5?action=delete"
// 	pathEx.path = 2a4e9608-cab6-bf5e-9b1e-29ebdd34bac5


// data: "2a4e9608-cab6-bf5e-9b1e-29ebdd34bac5"
// nodes[0]: pathEx = .../container
// nodes[1]: (has action) 

function stageNodes(actionNode: AiosNode, dataNode: AiosNode): void {
  const actionNodes = actionNode.nodes;
  const dataNodes = dataNode.nodes;
  if (!is(actionNodes) || !is(dataNodes)) {
    return;
  }
  const actionData = actionNodes[0];
  for (let i = 0; i < dataNodes.length; i++) {
    const dn = dataNodes[i];
    if (!is(dn.pathEx.action)) {
      if (is(actionData.nodes)) {
        // todo: check if there already
        continue;
      }
      const deletePath = createAiosPath({ path: dn.pathEx.path, action: 'delete' });
      const deleteNode = createAiosNode({
        pathEx: deletePath,
        path: deletePath.full,
        type: dn.type,
        item: dn.item,
        ui: {
          selected: true
        }
      });
      actionData.nodes = add(actionData.nodes, deleteNode);
      // todo: nested
    }
  }
  dataNodes.forEach(dn => {
    // find corresponding node
    const an = actionNodes.find(an => an.path === dn.path);
    if (is(an)) {
      console.log('load it?action=delete');
    }
  });
}
