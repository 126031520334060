import { isFail, AiosStatus, delay, is, isWait, AiosType, rem } from 'aios';
import { type AiosNode } from 'app/AiosNode';
import { getAiosNodeAction, setAiosNodeAction } from 'app/AiosNode/AiosNodeApi';
import { AppState } from 'signals/AppState/AppState';
import { removeNodeLast } from './removeNodeLast';
import { AuthState } from 'signals/AppState/AuthState';

export async function removeNode(node: AiosNode): Promise<void> {
    const { status, progress } = getAiosNodeAction(node);
    if (isFail(status) || !isWait(status)) {
        return;
    }
    if (!is(progress) || progress < 0.6) {
        setAiosNodeAction(node, { text: 'removing', status: AiosStatus.Processing, progress: 0.6 });
        void delay(async () => { await removeNode(node); });
        AppState.current.refresh();
        return;
    }
    if (progress < 1.0) {
        const remove = node.nodes?.[0];
        if (!is(remove)) {
            setAiosNodeAction(node, { text: 'removing last', status: AiosStatus.Fail, progress: undefined });
            AppState.current.refresh();
            return;
        }
        if (progress === 0.6) {
            setAiosNodeAction(node, { text: 'removing last', status: AiosStatus.Processing, progress: 0.7 });
        }
        const removed = await removeNodeLast(remove);
        if (removed === undefined) {
            void delay(async () => { await removeNode(node); });
            AppState.current.refresh();
            return;
        }
        if (!is(removed)) {
            setAiosNodeAction(node, { text: 'removing nodes', status: AiosStatus.Fail, progress: undefined });
            return;
        }
    }
    if (node.pathEx.aiosType === AiosType.Account) {
        await AuthState.current.signOut();
        window.location.href = '/aios';
        return;
    }
    const objectNode = node.parent;
    const rootNode = objectNode?.parent;    
    if (is(rootNode) && is(objectNode)) {
      rootNode.nodes = rem(rootNode.nodes, objectNode);
      AppState.current.goTo(rootNode.pathEx);
      return;
    }

    // const parent = node.parent?.parent;
    // const child = node.parent;
    // if (is(parent) && is(child)) {
    //     const path = createAiosPath({ path: parent.item?.path });
    //     parent.nodes = rem(parent.nodes, child);
    //     AppState.current.goTo(path);
    //     return;
    // }
    setAiosNodeAction(node, { text: 'removing', status: AiosStatus.Ok, progress: 1.0 });
    AppState.current.refresh();
}