import { AiosAccountSettingTheme } from './AiosAccountSetting';

export const AiosAccountSchema = {
  title: 'Account',
  type: 'object',
  required: ['path', 'email'],
  properties: {
    path: {
      type: 'string',
      title: 'path',
    },
    default: {
      type: 'string',
      title: 'default',
    },
    email: {
      type: 'string',
      title: 'email',
    },
    setting: {
      type: 'object',
      title: 'setting',
      properties: {
        theme: {
          type: 'string',
          title: 'theme',
          enum: [AiosAccountSettingTheme.Dark, AiosAccountSettingTheme.Light],
        },
      },
    },
  },
};

export const AiosAccountSchemaUi = {
  path: {
    'ui:diabled': true,
  },
};

export default AiosAccountSchema;
