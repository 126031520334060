import {
  type AiosPath,
} from 'aios';
import { type AiosNode } from 'app';

export async function appNodeLoadAccess(
  node: AiosNode,
  target: AiosPath,
): Promise<void> {
  // const data = this.data;
  // if (is(data) && is(data.loaded)) {
  //   if (is(this.action?.output?.status) && !is(target.action)) {
  //     if (!isWait(this.action?.output?.status)) {
  //       setAiosNodeAction(this, { output: createAiosOutput() });
  //     }
  //   }
  //   appNodeLoadAccessUi.call(this);
  //   return;
  // }
  // if (isFail(this.action?.output?.status)) {
  //   appNodeLoadAccessUi.call(this);
  //   return;
  // }
  // if (this.path.modeId === 'new') {
  //   appNodeLoadAccessNew.call(this);
  // }
  // await appNodeLoadAccessData.call(this);
  // // setAiosNodeAction(this, { input: this.result.title, output: this.action?.output?.status });
  // appNodeLoadAccessUi.call(this);
}

// async function appNodeLoadAccessData(this: AiosNode): Promise<boolean> {
//   const data = this.data;
//   if (!is(data)) {
//     return false;
//   }
//   if (is(data.loaded)) {
//     return true;
//   }
//   if (is(this.path.action)) {
//     return true;
//   }
//   if (!is(this.path.modeId)) {
//     return true;
//   }
//   if (!is(await appNodeLoadData.call(this))) {
//     return false;
//   }
//   return true;
// }

// function appNodeLoadAccessNew(this: AiosNode): void {
//   const { output } = getAiosNodeAction(this);
//   const parent = this.parent as AiosNode;
//   const parentPath = parent.parent?.path?.path as string;
//   const newItem = createAiosAccess({
//     parent: parentPath,
//     actorId: anon,
//     read: true
//   });
//   this.data = {
//     type: AiosType.Access,
//     item: newItem,
//     loaded: true,
//   };
//   if (is(output)) {
//     output.setOk();
//   }
// }