import { type AiosPath, createAiosPath, is } from 'aios';
import { useLocation, useNavigate } from 'react-router-dom';

export function useRoute(): {
  path: AiosPath;
  goTo: (path: AiosPath) => void;
} {
  const location = useLocation();
  const navigate = useNavigate();
  const goTo = (path: AiosPath): void => {
    try {
      if (is(path.full)) {
        navigate(path.full);
        return;
      }      
      if (is(path.path)) {
        navigate(path.path);
        return;
      }
      throw new Error();
    } catch (e) {
      console.log(e);
    }
  };
  const path = location.pathname;
  const input = location.search;
  const current = createAiosPath({ path, input });
  return { path: current, goTo };
}
