import {
  type AiosAction,
  type AiosPath,
  AiosType,
  add,
  createAiosAsset,
  createAiosPath,
  is,
  isFail,
  AiosStatus,
  isWait,
  AiosAssetSchema,
  AiosFileType,
  isOk,
  AiosDataCommand,
} from 'aios';
import { createAiosNode, findAiosNode, type AiosNode } from '../../AiosNode';
import { getAiosNodeAction, setAiosNodeAction, addAiosNodeAction, processNodeData } from 'app/AiosNode/AiosNodeApi';
import { AppAssetNode } from 'views';
import { setAiosNodeUiData } from 'app/AiosNode/AiosNodeUi';
import { doCreateCancel } from '../save/doSave';
import { doReload } from './loadNodeAssetFunctions';
import { doRemoveCancel, doRemoveConfirm } from '../remove/doRemove';
import { createAiosAssetSample } from 'app/AiosNode/sample';

export async function loadNodeAsset(node: AiosNode, target: AiosPath): Promise<void> {
  const { action } = node.pathEx;
  const { status, progress, loaded } = getAiosNodeAction(node);
  if (!is(action)) {
    if (!is(loaded)) {
      if (!isFail(status)) {
        if (!isWait(status)) {
          setAiosNodeAction(node, { text: 'loading asset', status: AiosStatus.Processing });
          setAiosNodeUiData(node, { functions: { cancel: undefined } });
          return;
        }
        const loadAction = await loadAssetData(node);
        if (isOk(loadAction.output?.status)) {
          node.api.loaded = true;
          setAiosNodeUiData(node, { functions: { create: undefined, delete: undefined } });
          // if (is(node.path.version)) {
          //   if (node.path.version === 'all') {
          //     loadAssetUi(node);
          //     return;
          //   }
          //   if (node.path.version === 'new') {
          //     if (isWait(getAiosNodeAction(node)?.output?.status)) {
          //       loadAssetUi(node);
          //       return;
          //     }
          //     const saving = node.nodes?.[0];
          //     if (is(saving)) {
          //       const { status: savingStatus } = getAiosNodeAction(saving);
          //       if (isWait(savingStatus)) {
          //         loadAssetUi(node);
          //         return;
          //       }
          //       loadAssetUi(node);
          //       return;
          //     }
          //     // const versions = node.parent;
          //     // const asset = versions?.parent;
          //     // const { asset: copy } = getAiosNodeData();
          //     // const copy = asset?.data?.item as AiosAsset;
          //     // if (is(copy)) {
          //     //   const path = createAiosPath({ path: copy.path, version: '0.0.0' });
          //     //   const asset = createAiosAsset({ ...copy, id: undefined, path: path.path, version: '0.0.0' })
          //     //   node.data = {
          //     //     type: AiosType.Asset,
          //     //     item: asset
          //     //   }
          //     // }
          //     loadAssetUi(node);
          //     return;
          //   }
          //   if (!isWait(output?.status)) {
          //     setAiosNodeAction(node, {
          //       action: createAiosAction({
          //         output: createAiosOutput({
          //           text: 'loading asset',
          //           status: AiosStatus.Processing,
          //         })
          //       })
          //     });
          //     loadAssetUi(node);
          //     return;
          //   }
          //   await loadAssetData(node);
          //   loadAssetUi(node);
          //   return;
          // }
        } else {
          setAiosNodeUiData(node, { functions: { reload: doReload } });
        }
        addAiosNodeAction(node, loadAction);
        setAiosNodeAction(node);
        return;
      }
      setAiosNodeUiData(node, { functions: { reload: doReload } });
      return;
    }
    setAiosNodeUiData(node, { functions: { create: undefined, delete: undefined } });
    return;
  }
  if (action === 'create') {
    // //////////
    if (!is(loaded)) {
      const createNode = node.parent as AiosNode;
      const rootNode = createNode.parent  as AiosNode;
      const newAccount = createAiosAssetSample(AiosFileType.Container, rootNode?.pathEx.path as string);
      node.type = AiosType.Asset;
      // node.path = newAccount.path as string;
      node.path = node.pathEx.full as string;
      node.pathEx = createAiosPath({ full: node.path });;
      node.item = newAccount;

      // node.type = AiosType.Asset;
      // node.path = node.pathEx.full as string;
      // node.item = createAiosAssetSample(AiosFileType.Container, node.parent?.parent?.path as string);
      // node.item = createAiosAsset({
      //   path: node.pathEx.path,
      //   name: node.pathEx.name,
      //   file: createAiosFile({ type: AiosFileType.Container }),
      // });
      node.api.loaded = true;
      if (is(node.parent)) {
        node.parent.api.loaded = true;
      }
    }
    // //////////
    if (!is(progress) || progress < 0.6) {
      setAiosNodeAction(node, { text: 'creating asset', status: AiosStatus.Ok, loaded: true });
      setAiosNodeUiData(node, { form: AppAssetNode, schema: AiosAssetSchema, functions: { cancel: doCreateCancel } });
    }
    return;
  }
  // update, make copy, load nodes
  if (action === 'delete') {
    if (!is(node.nodes)) {

      // node
      // node?action=delete (head)
      //  nodes
      //    node?action=delete

      const removeNode = node.parent as AiosNode;
      node.nodes = add(node.nodes, createAiosNode({
        parent: node,
        pathEx: node.pathEx,
        path: node.path,
        type: removeNode.type,
        item: removeNode.item
      }))
    }
    if (!is(progress)) {
      setAiosNodeUiData(node, { form: AppAssetNode, functions: { cancel: doRemoveCancel, confirm: doRemoveConfirm } });
      return;
    }
    if (progress === 1) {
      setAiosNodeUiData(node, { form: AppAssetNode, functions: { ok: doRemoveCancel } });
      return;
    }

    // let { progress } = getAiosNodeAction(node);
    // if (!is(progress) || progress < 0.5) {
    //   const stage = node.parent as AiosNode;
    //   // const staged = await loadNodeNext(stage);
    //   // if (staged === undefined) {
    //   //   if (!is(progress) || progress == 0.4) {
    //   //     progress = 0.1;
    //   //   } else {
    //   //     progress = progress + 0.1;
    //   //   }
    //   //   setAiosNodeAction(node, { text: 'stage nodes', status: AiosStatus.Processing, progress });
    //   //   // void delay(async () => {
    //   //   //   await removeNode(node);
    //   //   // });
    //   //   // AppState.current.refresh();
    //   //   return;
    //   // }
    //   // if (is(staged)) {
    //   //   setAiosNodeAction(node, { text: 'confirming delete', status: AiosStatus.Processing, progress: 0.5 });
    //   // } else {
    //   //   setAiosNodeAction(node, { text: 'staging nodes', status: AiosStatus.Fail });
    //   // }
    //   // AppState.current.refresh();
    //   return;
    // }
    setAiosNodeUiData(node, { form: AppAssetNode, functions: { cancel: doRemoveCancel } });
  }
}

async function loadAssetData(node: AiosNode): Promise<AiosAction> {
  const action = await processNodeData(node, AiosDataCommand.Load);
  const asset = createAiosAsset(node.item);
  const { assets } = asset;
  // const { assets, versions, accesses } = asset;
  if (is(assets)) {
    for (let i = 0; i < assets.length; i++) {
      const child = assets[i];
      const childPath = createAiosPath({ path: child.path });
      let childNode = findAiosNode(node, childPath);
      if (!is(childNode)) {
        childNode = createAiosNode();
        node.nodes = add(node.nodes, childNode);
      }
      childNode.parent = node;
      childNode.pathEx = childPath;
      childNode.type = AiosType.Asset;
      childNode.item = child;
    }
  }
  return action;
  // if (is(versions)) {
  //   const versionsPath = createAiosPath({ path: node.path.path, version: 'all' });
  //   let versionNode = findAiosNode(node, versionsPath);
  //   if (!is(versionNode)) {
  //     versionNode = createAiosNode({ path: versionsPath, parent: node });
  //     node.nodes = add(node.nodes, versionNode);
  //   }
  //   for (let i = 0; i < versions.length; i++) {
  //     const child = versions[i];
  //     const childPath = createAiosPath({ path: child.path });
  //     let childNode = findAiosNode(node, childPath);
  //     if (!is(childNode)) {
  //       childNode = createAiosNode();
  //       versionNode.nodes = add(versionNode.nodes, childNode);
  //     }      
  //     childNode.parent = versionNode;
  //     childNode.path = childPath;
  //     childNode.type = AiosType.Asset;
  //     childNode.item = child;

  //     createAiosNodeData(node);
  //     childNode.id = child.id as string;
  //     childNode.name = child.name as string;
  //     childNode.data = { type: AiosType.Asset, item: child };
  //   }
  // }
  // if (is(accesses)) {
  //   const accesesPath = createAiosPath({ path: node.path.path, mode: 'access' });
  //   let accessNode = node.find(accesesPath);
  //   if (!is(accessNode)) {
  //     accessNode = createAiosNode({ path: accesesPath, parent: node });
  //     node.nodes = add(node.nodes, accessNode);
  //   }
  //   for (let i = 0; i < accesses.length; i++) {
  //     const child = accesses[i];
  //     const childPath = createAiosPath({
  //       path: node.path?.path,
  //       mode: 'access',
  //       modeId: child.id,
  //     });
  //     let childNode = node.find(childPath);
  //     if (!is(childNode)) {
  //       childNode = createAiosNode();
  //       accessNode.nodes = add(accessNode.nodes, childNode);
  //     }
  //     childNode.parent = accessNode;
  //     childNode.id = child.id as string;
  //     childNode.path = childPath;
  //     childNode.name = 'access';
  //     childNode.data = { type: AiosType.Access, item: child };
  //   }
  // }
  // const useData = node.data;
  // if (!is(useData)) {
  //   return false;
  // }
  // useData.item = asset;
}
