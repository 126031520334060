import { type ReactNode, useState } from 'react';
import { AuthState } from '../../signals/AppState/AuthState';
import { AuthContext } from './AuthContext';

export interface AuthProviderProps {
  children: ReactNode;
}

const authState = new AuthState();

export const AiosProvider = ({ children }: AuthProviderProps): JSX.Element => {
  const [state, setState] = useState<AuthState>(authState);
  state.refresh = (auth?: Partial<AuthState>) => {
    setState({ ...state, ...auth });
  };
  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};
