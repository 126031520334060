import { type AiosPath, AiosStatus, set, is, delay, isWait } from 'aios';
import { findAiosNode, type AiosNode } from 'app';
import { getAiosNodeAction } from 'app/AiosNode/AiosNodeApi';
import { AppState } from 'signals/AppState/AppState';
import { loadNode } from './loadNode';

interface AiosPathStatus {
  load: string;
  full: string;
  status: AiosStatus;
  progress: number | undefined;
}

const appPathStatus: AiosPathStatus = {
  load: '',
  full: '',
  status: AiosStatus.None,
  progress: undefined,
};

export async function loadPage(node: AiosNode, path: AiosPath): Promise<void> {
  if (appPathStatus.load === path.full) {
    const find = findAiosNode(node, path);
    if (is(find)) {
      const { output: findOutput } = getAiosNodeAction(find);
      if (appPathStatus.status === findOutput?.status && appPathStatus.progress === findOutput?.progress) {
        return;
      }
    }
  }
  appPathStatus.load = path.full ?? '';
  const load = await loadNode(node, path);
  if (!is(load)) {
    return;
  }
  const { output: loadOutput } = getAiosNodeAction(load);
  if (
    appPathStatus.full === path.full &&
    appPathStatus.status === loadOutput?.status &&
    appPathStatus.progress === loadOutput?.progress
  ) {
    return;
  }
  appPathStatus.full = set(path.full, undefined);
  appPathStatus.status = set(loadOutput?.status, undefined);
  appPathStatus.progress = set(loadOutput?.progress, undefined);
  if (isWait(loadOutput?.status)) {
    void delay(async () => {
      appPathStatus.load = '';
      await loadPage(node, path);
    });
  }
  AppState.current.refresh();
}
