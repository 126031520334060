export const AiosAssetSchema = {
  title: 'Details',
  type: 'object',
  required: ['name', 'file'],
  properties: {
    path: {
      type: 'string',
      title: 'path',
    },
    name: {
      type: 'string',
      title: 'name',
    },
    default: {
      type: 'string',
      title: 'default',
    },
    version: {
      type: 'string',
      title: 'version',
    },
    file: {
      title: 'file',
      type: 'object',
      properties: {
        type: {
          title: 'type',
          type: 'string',
          enum: [
            'Folder',
            'Markdown',
            'Data',
            'Text',
            'Code',
            'Json',
            'Image',
            'Audio',
            'Model',
            'Video',
          ],
        },
        text: {
          title: 'text',
          type: 'string',
        },
        base: {
          title: 'base',
          type: 'string',
        },
        mime: {
          title: 'mime',
          type: 'string',
        },
      },
    },
  },
};

export const AiosAccountAssetSchema = {
  title: 'folder',
  type: 'object',
  required: ['path', 'name'],
  properties: {
    path: {
      type: 'string',
      title: 'path',
    },
    name: {
      type: 'string',
      title: 'name',
    },
  },
};

export default AiosAssetSchema;
