export function getId(): string {
  const array = new Uint8Array(16);
  crypto.getRandomValues(array);
  const hex = Array.from(array)
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
  const id = `${hex.slice(0, 8)}-${hex.slice(8, 12)}-${
    hex.slice(
      12,
      16,
    )
  }-${hex.slice(16, 20)}-${hex.slice(20)}`;
  return id;
}

export default getId;
