import { set } from '../functions';

export type AiosObject = Record<string, unknown>;

export function createAiosObject(item: unknown): AiosObject | unknown {
  if (item === null || item === undefined) {
    return {};
  }
  if (Array.isArray(item)) {
    return item.map(createAiosObject);
  }
  if (typeof item === 'object') {
    const result: AiosObject = {};
    for (const key in item as AiosObject) {
      if (!Object.prototype.hasOwnProperty.call(item as AiosObject, key)) {
        continue;
      }
      const value = (item as AiosObject)[key];
      if (
        value === undefined ||
        value === null ||
        value === '' ||
        typeof value === 'function'
      ) {
        continue;
      }
      result[key] = createAiosObject(value);
    }
    return result;
  }
  if (['number', 'string', 'boolean', 'symbol'].includes(typeof item)) {
    return item;
  }
  return {};
}

export function getChanges(dest: AiosObject, src: AiosObject): AiosObject {
  const result: AiosObject = {};
  const allKeys = new Set([...Object.keys(dest), ...Object.keys(src)]);
  allKeys.forEach((key) => {
    const val1 = dest[key];
    const val2 = src[key];
    if (isObject(val1) && isObject(val2)) {
      const nestedDiff = getChanges(val1, val2);
      if (Object.keys(nestedDiff).length > 0) {
        result[key] = nestedDiff;
      }
      return;
    }
    if (Array.isArray(val1) && Array.isArray(val2)) {
      const arrayChanges = getArrayChanges(val1, val2);
      if (arrayChanges.length > 0) {
        result[key] = arrayChanges;
      }
      return;
    }
    if (val1 !== val2) {
      result[key] = set(val1, '');
    }
  });
  return result;
}

function getArrayChanges(
  destArray: unknown[],
  srcArray: unknown[],
): AiosObject[] {
  const differences: AiosObject[] = [];

  for (let i = 0; i < destArray.length; i++) {
    const destItem = destArray[i] as AiosObject;
    const srcItem = srcArray[i] as AiosObject;
    const diff = getChanges(destItem, srcItem);

    if (Object.keys(diff).length > 0) {
      differences.push(diff);
    }
  }

  return differences;
}

function isObject(value: unknown): value is AiosObject {
  return value !== null && typeof value === 'object' && !Array.isArray(value);
}
