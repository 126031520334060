import { type AiosFile } from 'aios';
import { Body, GridEx } from 'components';
import { AdminFile } from './AdminFile';

export interface AdminBodyProps {
  file: AiosFile;
  onSelect: (file: AiosFile) => void;
}

export const AdminBody = ({ file, onSelect }: AdminBodyProps): JSX.Element => {
  return (
    <Body>
      <div style={{ width: '100%', height: '100%', overflow: 'auto' }}>
        <GridEx size={5} cols0={10} rows>
          <AdminFile file={file} count={10} index={0} onSelect={onSelect} />
        </GridEx>
      </div>
    </Body>
  );
};
