import { createAiosPath, AiosStatus, is, createAiosOutput, delay, createAiosAction } from 'aios';
import { type AiosNode } from 'app';
import { getAiosNodeAction, addAiosNodeAction, setAiosNodeAction } from 'app/AiosNode/AiosNodeApi';
import { AppState } from 'signals/AppState/AppState';
import { AuthState } from 'signals/AppState/AuthState';

export async function doCancel(node: AiosNode): Promise<void> {
  const { output } = getAiosNodeAction(node);
  output.setFail({ status: AiosStatus.FailCancel });
  if (is(node.api.client)) {
      node.api.client.cancel();
      node.api.client = undefined;
  }
  AppState.current.refresh();
}

export async function doReload(node: AiosNode): Promise<void> {
  const { action } = getAiosNodeAction(node);
  addAiosNodeAction(node, action);
  setAiosNodeAction(node);
  AppState.current.refresh();
}

export async function doSignIn(): Promise<void> {
  const { full, goTo } = AppState.current;
  if (is(full)) {
    localStorage.setItem('aios_auth_path', full);
  }
  goTo(createAiosPath({ path: 'aios?action=signin' }));
}

export async function doSignInCancel(node: AiosNode): Promise<void> {
  AuthState.current.signInCancel();
  const { action, output } = getAiosNodeAction(node);
  output.setFail({ status: AiosStatus.FailCancel });
  addAiosNodeAction(node, action);
  setAiosNodeAction(node);
  AppState.current.goTo(createAiosPath({ path: 'aios' }));
}

export async function doSignOut(): Promise<void> {
  const { goTo } = AppState.current;
  goTo(createAiosPath({ path: 'aios?action=signout' }));
}

export async function doSignOutConfirm(node: AiosNode): Promise<void> {
  const { action, output } = getAiosNodeAction(node);
  setAiosNodeAction(node, {
    action: createAiosAction({
      output: createAiosOutput({
        text: 'logging out',
        status: AiosStatus.Processing,
      })
    })
  });
  AppState.current.refresh();
  void delay(async () => {
    if (!(await AuthState.current.signOut())) {
      output.setFail();
      addAiosNodeAction(node, action);
      return;
    }
    window.location.href = '/aios';
    output.setOk();
  });
}

export async function doSignOutCancel(node: AiosNode): Promise<void> {
  const { output } = getAiosNodeAction(node);
  output.setFail({ status: AiosStatus.FailCancel });
  AppState.current.goTo(createAiosPath({ path: 'aios' }));
  AppState.current.refresh();
}
