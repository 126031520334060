import { getClass } from 'signals';
import './Grid10.css';

export interface Grid10Props {
  children: React.ReactNode;
}

export const Grid10 = ({ children }: Grid10Props): JSX.Element => {
  return (
    <div className={getClass('grid10')}>
      {children}
    </div>
  );
}