import { type AiosPath, createAiosPath, is } from 'aios';
import { type AiosNode } from '../../AiosNode';
import { AppDataList } from 'views';
import { loadNodeData } from 'app/funcs/load/loadNodeData';
import { getAiosNodeAction } from 'app/AiosNode/AiosNodeApi';
import { setAiosNodeUiData } from 'app/AiosNode/AiosNodeUi';
import { AppState } from 'signals/AppState/AppState';

export async function appNodeLoadAction(
  node: AiosNode,
  target: AiosPath,
): Promise<AiosNode | undefined> {
  if (!(await appNodeLoadActionData(node))) {
    return node;
  }
  const thisFull = node.pathEx.full as string;
  const targetFull = target.full as string;
  if (thisFull !== targetFull) {
    return node;
  }
  appNodeLoadActionUi.call(node);
  const { output } = getAiosNodeAction(node);
  output.setOk();
  return node;
}

async function appNodeLoadActionData(node: AiosNode): Promise<boolean> {
  const { loaded } = getAiosNodeAction(node);
  if (is(loaded)) {
    return true;
  }
  if (is(node.pathEx.action)) {
    return true;
  }
  if (!is(await loadNodeData(node))) {
    return false;
  }
  return true;
}

function appNodeLoadActionUi(this: AiosNode): void {
  setAiosNodeUiData(this, {
    form: AppDataList,
    functions: {
      'close':
        async () => {
          if (is(this.pathEx.parent)) {
            AppState.current.goTo(createAiosPath({ path: this.pathEx.parent }));
          }
        }
    }
  });
}
